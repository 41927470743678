import { ConnectionMaster} from "../server-connection/connection-master";
import { ConnectionSettings } from "../connection-settings";
import { AppSettings } from "../app-settings";
import axios, { AxiosResponse, AxiosError } from 'axios';

interface LogonRequest {
    applicationId: number | null;
    applicationVersion?: string;
    userName: string | null;
    password: string | null;
  }
export class MarketDataClient {

    private static s_instance: MarketDataClient | null = null;

    private constructor() {}

    public static GetInstance(): MarketDataClient {
 
        if (!this.s_instance) 
            this.s_instance = new MarketDataClient();
         
         return this.s_instance;
    }

    private _connected = false;

    private _subscriptions:Subscription[] = [];

    private _connectionCallbacks: ConnectionCallback[] = [];

    private  _marketDataConnection: WebSocket | null = null;
 
    public Connect(connectionCallback?:ConnectionCallback): Promise<string> {
        return new Promise<string>((resolve,reject) => {
                   
            if( connectionCallback)
                this.AddConnectionCallback(connectionCallback);

            if( this._connected){  
                resolve("The MarketData Service is already connected");
                return;
            }
            
            const cs = ConnectionSettings.getInstance();
            const as = AppSettings.getInstance();

            //if (ConnectionMaster.Instance.lifecycleManager.connected()){

                const logonRequest: LogonRequest = 
                { applicationId: ConnectionSettings.getInstance().applicationId, 
                  applicationVersion: AppSettings.getInstance().tiApiVersion,
                  userName: ConnectionSettings.getInstance().userName, 
                  password: ConnectionSettings.getInstance().password
                };
        
                axios.post<LogonRequest>("https://" + AppSettings.getInstance().tiServicesHost + "/BrokersWebService/v1/Administration/logon?TI-Target-Group=DotNetServices", logonRequest)
                  .then((response: any ) => {
                    if( response.status === 200){
                        ConnectionSettings.getInstance().jwt = response.data.Data.Token;
                        this.startWebSocketConnection().then((message) => {
                            this._connected = true;
                            resolve(message);
                        });
                    }
                    else{
                        reject(response.message);
                    }
                  })   
                  .catch((error: AxiosError) => {
                      reject(error.message);
                  });         
            //}
            //else{
            //    reject("Must be connected before using the Market Data Service.");
            //}

        });
    }

    public AddConnectionCallback( connectionCallback: ConnectionCallback){
        this._connectionCallbacks.push(connectionCallback);
    }

    public SubscribeTradeQuote(symbol:string, location:string, unsubscribePrevious:boolean, tradeQuoteCallback: TradeQuoteCallback  ){
        
        if(unsubscribePrevious)
           this.unSubscribePrevious(location);

        let subscription =  this._subscriptions.find(obj => obj.symbol === symbol)

        if(subscription)
        {
            subscription.count++;
            subscription.locations.push({ id:location, tradeQuoteCallback:tradeQuoteCallback});

            let connectionMessage:ConnectionStaus = {
                Code: 2,
                Message: "Already Subscribed to Symbol:" + symbol 
            }
            this._connectionCallbacks.forEach(connectionCallback => {
                connectionCallback(connectionMessage);
            });

        }
        else
        {
            if ( this._connected)
            {
                let connectionMessage:ConnectionStaus = {
                    Code: 3,
                    Message: "Subscribing to Symbol:" + symbol 
                }
                this._connectionCallbacks.forEach(connectionCallback => {
                    connectionCallback(connectionMessage);
                });
    
                this._marketDataConnection?.send(JSON.stringify({ "MessageType": 1, "DataTypes": [3], "Symbols": [symbol] }));
            }
            subscription = {count:1, symbol:symbol, locations:[]};
            
            subscription.locations.push({ id:location, tradeQuoteCallback:tradeQuoteCallback})

            this._subscriptions.push(subscription);
        }

    }

    private unSubscribePrevious(location:string){
        
        for (let i = this._subscriptions.length - 1; i >= 0; i--)
        {
            for (let j = 0; j < this._subscriptions[i].locations.length; j++)
            {
                if (this._subscriptions[i].locations[j].id === location)
                {
                    this._subscriptions[i].locations.splice(j,1);
                    this._subscriptions[i].count--;
                    break;
                }
            }

            if( this._subscriptions[i].count === 0){

                this._marketDataConnection?.send(JSON.stringify({ "MessageType": 2, "DataTypes": [3], "Symbols": [this._subscriptions[i].symbol] }));
                this._subscriptions.splice(i, 1);
            }
        }
    }

    public UnSubscribeTradeQuote(symbol:string, location:string){
        
        let subscription = this._subscriptions.find(obj => obj.symbol === symbol)

        if(!subscription) return;
        
        for (let i = 0; i < this._subscriptions.length; i++)
        {
            if( this._subscriptions[i].symbol == symbol)
            {
                for (let j = 0; j < this._subscriptions[i].locations.length; j++)
                {
                    if (this._subscriptions[i].locations[j].id === location)
                    {
                        this._subscriptions[i].locations.splice(j,1);
                        break;
                    }
                }
                
                subscription.count--;

                if( subscription.count === 0){

                    this._marketDataConnection?.send(JSON.stringify({ "MessageType": 2, "DataTypes": [3], "Symbols": [symbol] }));
                    this._subscriptions.splice(i, 1);

                    let connectionMessage:ConnectionStaus = {
                        Code: 1,
                        Message: "UnSubscribing Symbol:" + symbol 
                    }
    
                    this._connectionCallbacks.forEach(connectionCallback => {
                        connectionCallback(connectionMessage);
                    });

                    break;
                }
            }
        }
    }
    
    private startWebSocketConnection(): Promise<string> {
        return new Promise<string>((resolve,reject) => {
            this._marketDataConnection = new WebSocket("wss://" +
                                                        AppSettings.getInstance().tiServicesHost + 
                                                        "/BrokersWebService/v1/MarketData/throttled-marketdata?" + 
                                                        "&TI-Target-Group=DotNetServices" +
                                                        "&applicationId=5" +
                                                        "&userName=" + ConnectionSettings.getInstance().userName + 
                                                        "&throttleMS="  + AppSettings.getInstance().tiMarketDataThrottleMs +
                                                        "&access_token="   + ConnectionSettings.getInstance().jwt);

            this._marketDataConnection.onopen = (event) => 
            {  
                this._subscriptions.forEach(subscription => {

                    let connectionMessage:ConnectionStaus = {
                        Code: 3,
                        Message: "On Open Connection, Subscribing to Symbol:" + subscription.symbol 
                    }
                    this._connectionCallbacks.forEach(connectionCallback => {
                        connectionCallback(connectionMessage);
                    });

                    this._marketDataConnection?.send(JSON.stringify({ "MessageType": 1, "DataTypes": [3], "Symbols": [subscription.symbol] }));
                });

                resolve("Connected")       
            };

            this._marketDataConnection.onerror = (event) => 
            {  
                reject("Not Connected");
            };

            this._marketDataConnection.onclose = (event:CloseEvent) => {
                    
                let connectionMessage:ConnectionStaus = {
                    Code: -1,
                    Message: "Market Data Connection Closed, attempting to reconnect..."
                }

                this._connectionCallbacks.forEach(connectionCallback => {
                    connectionCallback(connectionMessage);
                });

                setTimeout(() => this.startWebSocketConnection(), 10000);

            };

            this._marketDataConnection.onmessage = (event:MessageEvent) => {

                let message = JSON.parse(event.data);
                switch(message.MessageType)
                {
                    //Connection Opened 30:
                    //Heartbeat 31:
                    case 30:
                    case 31:
                        let connectionMessage:ConnectionStaus = {
                            Code: message.MessageType,
                            Message: message.Data ?? message.Message
                        }
                        this._connectionCallbacks.forEach(connectionCallback => {
                            connectionCallback(connectionMessage);
                        });
                        break;
                    case 53:
                        //TradeQuote
                        let prevClosePrice = message.Data[12];
                        let dollarChange =  (message.Data[3] - prevClosePrice);
                        let percentChange = (dollarChange/prevClosePrice)*100;

                        let tradeQuote:TradeQuote = {
                            Symbol: message.Data[0],
                            TradeTime: new Date(message.Data[1]),
                            TradeTimeUtc: new Date(message.Data[11]),
                            TradeSize: message.Data[2],
                            TradePrice: Number(message.Data[3].toFixed(2)),
                            QuoteTime: new Date(message.Data[4]),
                            BidSize: message.Data[5],
                            Bid: message.Data[6],
                            AskSize: message.Data[7],
                            Ask: message.Data[8],
                            Volume: message.Data[9],
                            ReportingExchange: message.Data[10],
                            DollarChange: Number(dollarChange.toFixed(4)),
                            PercentChange: Number(percentChange.toFixed(4)),
                            PreviousClose: prevClosePrice
                        };

                        this._subscriptions.forEach(subscription => {
                            if( subscription.symbol == tradeQuote.Symbol){
                                subscription.locations.forEach(location => {
                                    if(location.tradeQuoteCallback)
                                       location.tradeQuoteCallback(tradeQuote);
                                });
                            }
                        });

                        break;
                }
            };                                            
        });
    }
}

interface LogonRequest {
    applicationId: number | null;
    applicationVersion?: string;
    userName: string | null;
    password: string | null;
}
interface WebResult {

    Date:TokenReponse;
    Status:number; 
    Message:string;
}
interface TokenReponse {

     Token:string;
     UserName:string; 
     SessionId:string;
}
interface Subscription {

    count:number;
    symbol:string; 
    locations: Location[];
}

interface Location {

    id:string;

    tradeQuoteCallback: TradeQuoteCallback;
}


export type TradeQuoteCallback = (arg:TradeQuote) => void;
export type ConnectionCallback = (arg:ConnectionStaus) => void;
export type ConnectionStaus = {
    Code: number,
    Message: string
}
export type TradeQuote = {
    Symbol: string,
    TradeTime: Date,
    TradeTimeUtc: Date,
    TradeSize: number,
    TradePrice:number,
    QuoteTime:Date,
    BidSize:number,
    Bid:number,
    AskSize:number,
    Ask:number,
    Volume:number,
    ReportingExchange:string,
    PreviousClose: number,
    DollarChange:number,
    PercentChange:number
}