export class PriceAlertResponseModel {
    public id = '';
    public symbol = '';
    public price = 0;
    public currentPrice = 0;
    public tooltip = '';
    public status = '';
    public isLong = false;
    public longAfter = false;
    public dollarsSinceTriggered?: number;
    public percentSinceTriggered?: number;
    public invalidPrice?: number;
    public triggered?: Date;
    public expires?: Date;
    public created?: Date;
    public updated?: Date;
}