import { ClientResponseModel, SymbolListResponseModel } from "../lib-export/everything";
import { symbolListRequest } from "./api-request-builders/symbol-list-request.builder";
import { SymbolListXMLToResponseModelMapper } from "./mappers/symbol-list-xml-to-response-model.mapper";

export class SymbolListClient {
    constructor() {}

    public async getSymbolList(language: string): Promise<ClientResponseModel<Array<SymbolListResponseModel>>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Array<SymbolListResponseModel>>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     return responseModel;
        // } 

        const response = await symbolListRequest(language);
        
        const model = new ClientResponseModel<Array<SymbolListResponseModel>>(); 
        if (!response) {
            return model;
        }

        const result = SymbolListXMLToResponseModelMapper.map(response);
        
        model.isSuccess = true;
        model.data = result;

        return model;
    }
}