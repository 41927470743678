import { TIUtilities } from "../../utilities/ti-utilities";
import { DateHelpers } from "../helpers/date-helpers";
import { FormatHelpers } from "../helpers/format-helpers";
import { TopListDataResponseModel } from "../top-list/top-list-data-response.model";
import { QuoteFeedManager } from "../quote-feed/trade-quote.manager";
import { PriceAlertResponseModel } from "./price-alert-response.model";

// This class is taken from LimitAlertsManager.cs from TiPro
export class PriceAlertManager {
    private removedLimitAlertsId: Array<string> = [];
    private limitAlerts: Array<PriceAlertResponseModel> = [];

    private static instance: PriceAlertManager | null = null;

    private tradeQuoteManager: QuoteFeedManager;
    constructor() {
        this.tradeQuoteManager = QuoteFeedManager.getInstance();
    }

    public static getInstance(): PriceAlertManager {
        if (this.instance == null) {
            this.instance = new PriceAlertManager();
        }

        return this.instance;
    }

    public handleMessage(response: any) {
        if (!response?.type) {
            return;
        }

        const type = response.type;
        if (type == "alert" || type == "update") {
            const limitAlert = this.getLimitAlert(response);
            if (limitAlert && this.removedLimitAlertsId.indexOf(limitAlert.id) === -1) {
                const index = this.limitAlerts.findIndex(x => x.id == limitAlert.id);
                if (index == -1) {
                    this.limitAlerts.push(limitAlert);

                    this.tradeQuoteManager.addQuoteFeed(limitAlert?.symbol ?? '');
                }
                else {
                    this.limitAlerts[index] = limitAlert;
                }

                // List<LimitAlertsForm> limitAlertsForms = Application.OpenForms.OfType<LimitAlertsForm>().Where(x => !x.IsDisposed).ToList();
                // if (limitAlertsForms.Count > 0)
                // {
                //     foreach (LimitAlertsForm limitAlertsForm in limitAlertsForms)
                //     {
                //         limitAlertsForm.AddLimitAlert(limitAlert, type == "alert");
                //     }
                // }

                // List<Charts> chartForms = Application.OpenForms.OfType<Charts>().Where(x => !x.IsDisposed && x.Symbol == limitAlert.Symbol).ToList();
                // if (chartForms.Count > 0)
                // {
                //     foreach (Charts chart in chartForms)
                //     {
                //         chart.AddOrUpdateLimitAlertAnnotation(limitAlert);
                //     }
                // }
            }
        }
        else if (type == "status") {
            // GuiEnvironment.LoadingLimitAlerts = true;
            // GuiEnvironment.LimitAlerts.Clear();
            // We need to clear the oldestLimitAlertForDeletion limit alert by setting Id to an empty string.
            // GuiEnvironment.oldestLimitAlertForDeletion.Id = "";
            // XmlNode source = message;
            const alertCount = response["COUNT"] ?? 0;
            // if (null != source)
            //     GuiEnvironment.LogMessage("[Limit Alert Status Msg] Cleared alerts and alert count from status is " + alertCount + ", outerxml=" + source.OuterXml);
            // else
            //     GuiEnvironment.LogMessage("[Limit Alert Status Msg] Cleared alerts and alert count from status is " + alertCount + ", outerxml= null");
            for (let i = 1; i <= alertCount; i++) {
                const limitAlert = this.getLimitAlert(response, i);
                if (limitAlert && this.removedLimitAlertsId.indexOf(limitAlert.id) === -1) {
                    const index = this.limitAlerts.findIndex(x => x.id == limitAlert.id);
                    if (index == -1) {
                        this.limitAlerts.push(limitAlert);

                        this.tradeQuoteManager.addQuoteFeed(limitAlert?.symbol ?? '');
                    }
                    else {
                        this.limitAlerts[index] = limitAlert;
                    }
                }
            }
            // GuiEnvironment.LoadingLimitAlerts = false;

            /*
            List<LimitAlertsForm> limitAlertsForms = Application.OpenForms.OfType<LimitAlertsForm>().ToList();
            GuiEnvironment.LogMessage("[Limit Alert Status Msg] Found " + limitAlertsForms.Count + " price alert forms in OpenForms");
            if (limitAlertsForms.Count > 0)
            {
                foreach (LimitAlertsForm limitAlertsForm in limitAlertsForms)
                {
                    limitAlertsForm.MergeAlerts();
                    limitAlertsForm.UpdatePandLCharts();
                }
            }
            */
            // if (null != StatusProcessed)
            //     StatusProcessed();

            // UpdateLimitAlertAnnotations();
        }
        else if (type == "removed") {
            const limitAlert = this.getLimitAlert(response);
            if (limitAlert) {
                const index = this.limitAlerts.findIndex(x => x.id == limitAlert.id);
                if (index > -1) {
                    this.limitAlerts.splice(index, 1);
                }
                const removedIndex = this.removedLimitAlertsId.findIndex(x => x == limitAlert.id);
                if (removedIndex > -1) {
                    this.removedLimitAlertsId.splice(removedIndex, 1);
                }
                // GuiEnvironment.LimitAlerts.Remove(limitAlert);
                // List<LimitAlertsForm> limitAlertsForms = Application.OpenForms.OfType<LimitAlertsForm>().Where(x => !x.IsDisposed).ToList();
                // if (limitAlertsForms.Count > 0)
                // {
                //     foreach (LimitAlertsForm limitAlertsForm in limitAlertsForms)
                //     {
                //         limitAlertsForm.RemoveLimitAlert(limitAlert);
                //     }
                // }

                // List<Charts> chartForms = Application.OpenForms.OfType<Charts>().Where(x => !x.IsDisposed && x.Symbol == limitAlert.Symbol).ToList();
                // if (chartForms.Count > 0)
                // {
                //     foreach (Charts chart in chartForms)
                //     {
                //         chart.RemovedLimitAlertAnnotation(limitAlert.Id);
                //     }
                // }
                // // We need to clear the oldestLimitAlertForDeletion limit alert if it was removed by setting Id to an empty string.
                // if (GuiEnvironment.oldestLimitAlertForDeletion.Id == limitAlert.Id)
                //     GuiEnvironment.oldestLimitAlertForDeletion.Id = "";
            }
        }
        else if (type == "curve") {
            // A user was getting an "Object reference not set to an instance of an object." exception.
            // We need to check if message is not null.
            // int alertCount = source.Property("COUNT", 0);
            // for (int i = 1; i <= alertCount; i++) {
            //     string intradayNumbers = source.Property("INTRADAY_CURVE" + i, "");
            //     string dailyNumbers = source.Property("DAILY_CURVE" + i, "");
            //     string id = source.Property("ID" + i, "");
            //     // try
            //     // {
            //     //     var matches = GuiEnvironment.LimitAlerts.Where(x => x.Id == id);
            //     //     if (matches.Count() == 1)
            //     //     {
            //     //         LimitAlert limitAlert = matches.First();
            //     //         if (null != limitAlert)
            //     //         {
            //     //             if (intradayNumbers != "")
            //     //             {
            //     //                 Thermograph intradayThermograph = RowDataHelper.GetThermograph(intradayNumbers, 1);
            //     //                 limitAlert.IntradayPerformance = intradayThermograph;
            //     //             }
            //     //             if (dailyNumbers != "")
            //     //             {
            //     //                 Thermograph dailyThermograph = RowDataHelper.GetThermograph(dailyNumbers, 1);
            //     //                 limitAlert.DailyPerformance = dailyThermograph;
            //     //             }
            //     //         }
            //     //     }
            //     // }
            //     // catch (Exception e)
            //     // {
            //     //     string debugView1 = e.StackTrace;
            //     // }
            // }
            // List<LimitAlertsForm> limitAlertsForms = Application.OpenForms.OfType<LimitAlertsForm>().Where(x => !x.IsDisposed).ToList();
            // if (limitAlertsForms.Count > 0)
            // {
            //     foreach (LimitAlertsForm limitAlertsForm in limitAlertsForms)
            //     {
            //         limitAlertsForm.UpdatePandLCharts();
            //     }
            // }
        }
    }

    public getAlerts(): Array<PriceAlertResponseModel> {
        if (!this.limitAlerts?.length) {
            return [];
        }

        return this.limitAlerts.filter((x: PriceAlertResponseModel) => this.removedLimitAlertsId.indexOf(x.id) === -1);
    }

    public addRemovedId(id: string) {
        if (id && this.removedLimitAlertsId.indexOf(id) > -1) {
            return;
        }

        this.removedLimitAlertsId.push(id);
    }

    public updateLimitAlertsWithTopListData(symbol: string, toplistData: TopListDataResponseModel) {
        if (!this.limitAlerts?.length || !toplistData || !toplistData.rows?.length) {
            return;
        }

        for (const alert of this.limitAlerts) {
            if (alert.symbol?.toLowerCase() !== symbol?.toLowerCase()) {
                return;
            }

            alert.currentPrice = parseFloat(toplistData.rows[0].get('[Price]') ?? '');
            alert.dollarsSinceTriggered = undefined;
            alert.percentSinceTriggered = undefined;

            if (alert.triggered && alert.currentPrice) {
                if (alert.longAfter) {
                    alert.dollarsSinceTriggered = alert.currentPrice - alert.price;
                } else {
                    alert.dollarsSinceTriggered = alert.price - alert.currentPrice;
                }
            }


            if (alert.triggered && alert.dollarsSinceTriggered) {
                alert.percentSinceTriggered = alert.dollarsSinceTriggered / alert.price * 100;
            }
        }
    }

    private getLimitAlert(source: any, alertNumber?: number): PriceAlertResponseModel | null {
        if (null == source)
            return null;

        let propertySuffix = "";
        if (alertNumber && alertNumber > 0)
            propertySuffix = alertNumber + '';

        const status = source["STATUS" + propertySuffix] ?? "WORKING";
        const limitAlert = new PriceAlertResponseModel();
        limitAlert.symbol = source["SYMBOL" + propertySuffix] ?? "";
        limitAlert.id = source["ID" + propertySuffix] ?? "";
        limitAlert.price = source["PRICE" + propertySuffix] ?? 0;
        limitAlert.status = status;

        limitAlert.isLong = (source["IS_LONG" + propertySuffix] ?? "1") === "1";
        limitAlert.longAfter = (source["LONG_AFTER" + propertySuffix] ?? "1") === "1";

        const triggered = source["TRIGGERED" + propertySuffix] ?? -1;
        if (triggered != -1) {
            limitAlert.triggered = TIUtilities.decodeServerTime(triggered);
        }

        const created = source["CREATED" + propertySuffix] ?? -1;
        if (created != -1) {
            limitAlert.created = TIUtilities.decodeServerTime(created);
        }

        const updated = source["UPDATED" + propertySuffix] ?? -1;
        if (updated != -1) {
            limitAlert.updated = TIUtilities.decodeServerTime(updated);
        }

        const expires = source["EXPIRES" + propertySuffix] ?? -1;
        if (expires != -1) {
            limitAlert.expires = TIUtilities.decodeServerTime(expires);
        }

        const invalidPrice = source["INVALID_PRICE" + propertySuffix] ?? -1;
        if (invalidPrice != -1) {
            limitAlert.invalidPrice = invalidPrice;
        }

        this.setAlertTooltip(limitAlert);

        return limitAlert;
    }

    private setAlertTooltip(limitAlert: PriceAlertResponseModel) {
        let tooltip = "";
        if (limitAlert.isLong) {
            tooltip = "Alert (" + (limitAlert.longAfter ? "Long" : "Short") + ") if price of " + limitAlert.symbol + " rises to $" + FormatHelpers.numberToString(limitAlert.price);
            if (limitAlert.expires)
                tooltip += " before " + DateHelpers.format('yyyy-mm-dd SH:MM:s tt', limitAlert.expires);
            if (limitAlert.invalidPrice)
                tooltip += " unless price falls to $" + FormatHelpers.numberToString(limitAlert.invalidPrice) + " first";
        }
        else {
            tooltip = "Alert (" + (limitAlert.longAfter ? "Long" : "Short") + ") if price of " + limitAlert.symbol + " falls to $" + FormatHelpers.numberToString(limitAlert.price);
            if (limitAlert.expires)
                tooltip += " before " + DateHelpers.format('yyyy-mm-dd SH:MM:s tt', limitAlert.expires);
            if (limitAlert.invalidPrice)
                tooltip += " unless price rises to $" + FormatHelpers.numberToString(limitAlert.invalidPrice) + " first";
        }

        tooltip += " (" + limitAlert.status + ")";
        limitAlert.tooltip = tooltip;
    }
}