import { BrokerAccount } from "./broker-account";
import { BrokerPosition } from "./broker-position";
import { BrokerOrder } from "./broker-order";
import { BrokerExecution } from "./broker-execution";

export class BrokerPortfolio {

    public constructor() {

        this.Accounts = new Map<string, BrokerAccount>; 
        this.Positions = new Map<string, BrokerPosition>; 
        this.Orders = new Map<string, BrokerOrder>; 
        this.Executions = new Map<string, BrokerExecution>; 

    }

    public Accounts : Map<string, BrokerAccount>;

    public Positions : Map<string, BrokerPosition>;

    public Orders : Map<string, BrokerOrder>;

    public Executions : Map<string, BrokerExecution>;

}