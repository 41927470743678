import { parseXml } from "phil-lib/misc";
import { COMMAND, Connection, ServerCommand } from "../../services/connection.client";
import { ChartIndicatorResponeModel } from "../models/chart-indicators/chart-indicator-response.model";
import { IndicatorTIQXmlToResponseModel } from "../mappers/indicator-tiq-xml-to-response-model.mapper";

export class ChartIndicatorsRequestBuilder {

    private static instance: ChartIndicatorsRequestBuilder;
    public static getInstance(): ChartIndicatorsRequestBuilder {
        if (!this.instance) {
            this.instance = new ChartIndicatorsRequestBuilder();
        }

        return this.instance;
    }

    public chartIndicatorRequest(): Promise<Array<ChartIndicatorResponeModel>> {
        return new Promise<Array<ChartIndicatorResponeModel>>((r) => {
            const messageToServer = [
                [COMMAND, "chart_indicators"],
                ["subcommand", "me_get_config"]
            ];

            let items: Array<ChartIndicatorResponeModel> = [];
            const cancelToken = Connection.getInstance().sendWithStreamingResponse(messageToServer as ServerCommand, (response: string) => {
                const xml = parseXml(response);
                if (xml) {
                    const type = xml.getAttribute('type');
                    if (type == 'columns') {
                        items = IndicatorTIQXmlToResponseModel.map(xml);
                    } else if (type == 'parameters') {
                        IndicatorTIQXmlToResponseModel.mapParameters(xml, items);
                    } else if (type == 'config_complete') {
                        cancelToken?.();
                        r(items);
                    }
                }
            });
        });
    }
}