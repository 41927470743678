import { QueryStringHelpers } from "./services/models/helpers/query-string-helpers";

export class ConnectionSettings {
    public location: string | null = null;
    public applicationId: number | null = null;
    public destination: string | null = null;
    public traceLogging = false;
    public userName: string | null = null;
    public password: string | null = null;
    public jwt: string | null = null;

    private static instance: ConnectionSettings | null = null;

    public static getInstance(): ConnectionSettings {
        if (!this.instance) {
            this.instance = new ConnectionSettings();
        }
        return this.instance;
    }

    public static setInstance(location:string, applicationId: number,  userName?: string, password?: string, destination?: string, traceLogging?: boolean) {

        if (!this.instance) {
            this.instance = new ConnectionSettings();
        }

        this.instance.applicationId = applicationId;
        this.instance.userName = userName ?? null;
        this.instance.password = password ?? null;
        this.instance.destination = destination ?? null;
        this.instance.location = location ?? null;
        this.instance.traceLogging = this.handleTraceLogging(traceLogging);

        this.instance.jwt = null;

    }

    public static handleTraceLogging(traceLogging?: boolean): boolean {
        const traceLoggingIsSet = traceLogging != undefined && traceLogging != null;
        if (traceLoggingIsSet) {
            return traceLogging;
        }

        // const envMode = import.meta.env.MODE;
        // const isTestEnv = envMode.toLowerCase() == 'test';
        const traceLoggingEnabledValue = QueryStringHelpers.get('TraceLoggingEnabled');
        if (traceLoggingEnabledValue.toLowerCase() == 'true') {
            return true;
        }
        
        return false;

        //sessionStorage not supported in Shared Workers 
        // const traceLoggingEnabledSessionKey = 'TraceLoggingEnabled';
        // if (traceLoggingEnabledValue.toLocaleLowerCase() == 'true') {
        //     sessionStorage.setItem(traceLoggingEnabledSessionKey, 'true');
        //     return true;
        // } else if (traceLoggingEnabledValue.toLocaleLowerCase() == 'false') { 
        //     sessionStorage.removeItem(traceLoggingEnabledSessionKey);
        //     return false;
        // }

        // const traceLoggingEnabledInSession = sessionStorage.getItem(traceLoggingEnabledSessionKey);
        // return traceLoggingEnabledInSession?.toLocaleLowerCase() == 'true';
    }
}