import { PriceAlertXMLModel } from "../models/price-alert/price-alert-xml.model";

export class PriceAlertXMLToResponseModelMapper {
    public static map(rawXML: Element): PriceAlertXMLModel | null {
        let result: PriceAlertXMLModel | null = null;
        if (rawXML) {
            let model: any = {};
            for (const attr of Array.from(rawXML.attributes)) {
                model[attr.name] = attr.value;
            }

            result = model;
        }

        return result;
    }
}