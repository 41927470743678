import { parseXml } from "phil-lib/misc";

import {COMMAND, Connection, ServerCommand} from "../../services/connection.client";

export async function symbolListRequest(language: string): Promise<Element | undefined> {

    const messageToServer = [
        [COMMAND, "edit_top_list_config"],
        ["settings", ""],
        ["symbol_list_folders", "1"],
        ["allow_negative_list_ids", true],
        ["language", language]
    ] as ServerCommand;

    var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
  
    const xml = parseXml(response);

    return xml;
}