
//ID,SYMBOL,IS_LONG,NEW,ENTRY_TIME,ENTRY_PRICE,STOP_PRICE,TARGET_PRICE,EXIT_PRICE,EXIT_TIME,TIME_STOP,PROFIT,EXIT_REASON,SMART_STOP,LAST,MAX_PROFIT,
//MAX_PROFIT_TIME,MIN_PROFIT,MIN_PROFIT_TIME,DISTANCE_FROM_STOP_PRICE,DISTANCE_FROM_TARGET_PRICE,PROFIT_HISTORY,PROFIT_HISTORY_NONEXIT,
//PROFIT_CHANGE_15,PROFIT_CHANGE_5,NONEXIT_PROFIT,TRADE_ZONE,ID2,REENTER_TIME,REENTER_PRICE,REENTER_STOP_PRICE,NEW_REENTER,FULL_RISK_PROFIT,
//PROFIT_HISTORY_FULLRISK,SEGMENT,SEGMENT_NAME


export class AiStrategyTrade {
                                                             //CSV Field
    public id: string | undefined;                           //ID
    public symbol: string | undefined;                       //SYMBOL
    public isLong: boolean  = false;                         //IS_LONG
    public new: boolean  = false;                            //NEW
    public entryTime: Date | undefined;                      //ENTRY_TIME
    public entryPrice: number | undefined;                   //ENTRY_PRICE
    public stopPrice: number | undefined;                    //STOP_PRICE
    public targetPrice: number | undefined;                  //TARGET_PRICE        
    public exitPrice: number | undefined;                    //EXIT_PRICE
    public exitTime: Date | undefined;                       //EXIT_TIME
    public timeStop: Date | undefined;                       //TIME_STOP
    public profit: number | undefined;                       //PROFIT 
    public exitReason: string | undefined;                   //EXIT_REASON
    public smartStop: number | undefined;                    //SMART_STOP
    public last: number | undefined;                         //LAST
    public maxProfit: number | undefined;                    //MAX_PROFIT
    public maxProfitTime: Date | undefined;                  //MAX_PROFIT_TIME
    public minProfit: number | undefined;                    //MIN_PROFIT
    public minProfitTime: Date | undefined;                  //MIN_PROFIT_TIME
    public distanceFromStopPrice: number | undefined;        //DISTANCE_FROM_STOP_PRICE 
    public distanceFromTargetPrice: number | undefined;      //DISTANCE_FROM_TARGET_PRICE
    public profitHistory: Array<number> | undefined;         //PROFIT_HISTORY
    public profitHistoryNonExit: Array<number> | undefined;  //PROFIT_HISTORY_NONEXIT
    public profitChange15: number | undefined;               //PROFIT_CHANGE_15
    public profitChange5: number | undefined;                //PROFIT_CHANGE_5
    public nonExitProfit: number | undefined;                //NONEXIT_PROFIT
    public tradeZone: string | undefined;                    //TRADE_ZONE
    public id2: string | undefined;                          //ID2
    public reenterTime: Date | undefined;                    //REENTER_TIME
    public reenterPrice: number | undefined;                 //REENTER_PRICE 
    public reenterStopPrice: number | undefined;             //REENTER_STOP_PRICE 
    public newReenter: boolean | undefined;                  //NEW_REENTER
    public fullRiskProfit: number | undefined;               //FULL_RISK_PROFIT
    public profitHistoryFullRisk: Array<number> | undefined;        //PROFIT_HISTORY_FULLRISK
    public segmentCode: string | undefined;                  //SEGMENT
    public segmentName: string | undefined;                  //SEGMENT_NAME 
    

}