import { Grid, Options } from "../../../javascript-api/single-grid-request";
import { encodePrototype } from "../../../tipro-data/single-grid-request";

export class ChartDataRequestModel {
    public symbols: Array<string> = ["DELL", "MSFT", "ORCL"];
    public gridRequest: Grid = [
        ["START", ['current("start")']],
        ["END", ['current("end")']],
        ["OPEN", []],
        ["HIGH", []],
        ["LOW", []],
        ["CLOSE", []],
        // ["VOLUME", []],
        // ["Average Volume", ['average(10,1,"VOLUME",1)']],
    ];
    public rowCount = 350;
    public extraRowCount = 0;
    public startBefore: Date | undefined = undefined;
    public sinceDate: Date | undefined = undefined;
    public options: Options = { timeFrame: 'daily', rowCount: this.rowCount };

    public getAmbiancePrototype(): string {
        return encodePrototype(
            this.gridRequest,
            this.options
        );
    }
}