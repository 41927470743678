import { TopListHistoryDataResponeModel } from "../models/top-list/top-list-history-data-response.model";

export class XmlAttributeToJsonMapper {
  public static map(xml: Element): any {
    let json: any = {};
    for (const element of Array.from(xml.children)) {
      const elementJSON: any = {};
      for (const attr of Array.from(element.attributes)) {
        elementJSON[attr.name] = attr.value;
      }

      if (element.children.length) {
        for (const child of Array.from(element.children)) {
          const childValue = this.childXMLToJSON(child);
          elementJSON[child.tagName] = childValue;
        }
      }

      json[element.tagName] = elementJSON;
    }

    return json;
  }

  public static childXMLToJSON(element: Element): any {
    if (!element || !element.children?.length) {
      return null;
    }

    if (element.children.length == 1) {
      if (!element.attributes?.length) {
        return null;
      }

      const elementJSON: any = {};
      for (const attr of Array.from(element.attributes)) {
        elementJSON[attr.name] = attr.value;
      }

      return elementJSON;
    } else {
      const elementArray: Array<any> = [];
      for (const childElement of Array.from(element.children)) {
        const elementJSON: any = {};
        elementJSON['NAME'] = childElement.tagName;
        for (const attr of Array.from(childElement.attributes)) {
          elementJSON[attr.name] = attr.value;
        }

        elementArray.push(elementJSON)
      }

      return elementArray;
    }
  }
}