import { MetaData } from "../../tipro-data/top-list-request";
import { AlertHistoryMetaDataResponeModel } from "../models/alert-history/alert-history-meta-data-response.model";
import { AlertHistoryResponeModel } from "../models/alert-history/alert-history-response.model";

export class AlertHistoryXMLToResponseModelMapper {
    public static map(rawXML: Element): Array<AlertHistoryResponeModel> {
        const result: Array<AlertHistoryResponeModel> = [];
        if (rawXML.children?.length) {
            const children = rawXML.children.item(0)?.children;
            if (children?.length) {
                for (const child of Array.from(children)) {
                    const model: any = {};
                    for (const attr of Array.from(child.attributes)) {
                        model[attr.name] = attr.value;
                    }

                    model.fromAlertHistory = true;
                    result.push(model);
                }
            }
        }
        return result;
    }

    public static mapMetaData(rawXML: Element): MetaData {
        const result: any = {};

        if (rawXML.children?.length) {
            const first = rawXML?.children?.item(0);
            if (first) {
                result.ALERTS = {
                    COLUMNS: [],
                    SHORT_FORM: first.getAttribute('SHORT_FORM'),
                    WINDOW_NAME: first.getAttribute('WINDOW_NAME')
                };
                const children = first.children?.item(0)?.children;
                if (children) {
                    for (const item of Array.from(children)) {
                        const model: any = {};

                        model.NAME = item.tagName;
                        
                        for (const attr of Array.from(item.attributes)) {
                            model[attr.name] = attr.value;
                        }
                        
                        result.ALERTS.COLUMNS.push(model);
                    }
                }
            }
        }
        
        const metaData: MetaData = { metaDataXml: rawXML.outerHTML, metaDataJson: result };
        return metaData;
    }
}