import { SingleGridResponse } from "../javascript-api/single-grid-request";
import { ProfitBackfillRequestBuilder } from "./api-request-builders/profit-backfill-request.builder";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { ProfitBackfillRequestModel } from "./models/profit-backfill/profit-backfill-request.model";
import {ConnectionMaster} from "../server-connection/connection-master"

export class ProfitBackfillClient {
    
    private profitBackfillRequestBuilder: ProfitBackfillRequestBuilder;
    private id = IdGenerator.NewId();
    
    constructor(connection?:ConnectionMaster | undefined) {
        
        this.profitBackfillRequestBuilder = ProfitBackfillRequestBuilder.getInstance();

        if(!(typeof connection === 'undefined')){
           this.profitBackfillRequestBuilder.setConnection(connection);
        }
    }
   

    public async getProfitBackfill(requestModel: ProfitBackfillRequestModel): Promise<ClientResponseModel<Map<string, SingleGridResponse>>> {
        const response = await this.profitBackfillRequestBuilder.profitBackfillListenRequest(requestModel);
        
        const model = new ClientResponseModel<Map<string, SingleGridResponse>>();
        model.isSuccess = true;
        model.data = response;
        return model;
    }
}