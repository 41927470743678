import { Data, MetaData } from "../tipro-data/top-list-request";
import { TopListHistoryRequestBuilder } from "./api-request-builders/top-list-history.request.builder";
import { TopListHistoryXMLToResponseModelMapper } from "./mappers/top-list-history-xml-to-response-model.mapper";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { TopListHistoryDataRequestModel } from "./models/top-list/top-list-history-data-request.model";

export class TopListHistoryClient {

    private builder: TopListHistoryRequestBuilder;
    private id =  IdGenerator.NewId();

    constructor() {
        this.builder = TopListHistoryRequestBuilder.getInstance();
    }

    private cancelToken: any;
    public getTopListHistory(requestModel: TopListHistoryDataRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback: Function) {
        this.start(requestModel, setResponseOnDataCallback, setResponseOnMetadataCallback);
    }

    public subscribe(requestModel: TopListHistoryDataRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback: Function) {
        this.start(requestModel, setResponseOnDataCallback, setResponseOnMetadataCallback);
        return this;
    }

    private start(requestModel: TopListHistoryDataRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback: Function) {
        this.unsubscribe();
        
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Data>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     setResponseOnDataCallback(responseModel); 
        //     return;
        // }

        // if (this.cancelToken) {
        //     TraceLoggingHelpers.log('TopListHistory Cancel Token Fire, ', this.id);
        //     this.cancelToken();
        //     this.cancelToken = null;
        // }
        
        // this.stopTopListHistory();
        // if (this.id) {
        //     this.stopTopListHistory();
        // }

        requestModel.removeConfigStringPropertyIfPropertyNameSet('resultCount', 'count')
        requestModel.addPropertyToConfigStringIfPropertyNameSet('resultCount', 'count')

        this.builder.topListHistoryListenRequest(this.id, (responseXML: any) => {
            // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
            //     const responseModel = new ClientResponseModel<Data>();
            //     responseModel.isSuccess = false;
            //     responseModel.message = 'Not logged in, please Login';
            //     setResponseOnDataCallback(responseModel); 
            //     return;
            // }
    
            const responseModel = new ClientResponseModel<Data>();
            if (responseXML) {
                responseModel.isSuccess = true;
                responseModel.data = TopListHistoryXMLToResponseModelMapper.map(responseXML);
                setResponseOnDataCallback(responseModel);
            } else {
                responseModel.isSuccess = false;
                responseModel.message = 'Response XML Empty';
                setResponseOnDataCallback(responseModel);
            }
        },
        (metaData: any) => {
            const responseModel = new ClientResponseModel<MetaData>();
            if (metaData) {
                responseModel.isSuccess = true;
                responseModel.data = metaData;
                setResponseOnMetadataCallback(responseModel);
            } else {
                responseModel.isSuccess = false;
                responseModel.message = 'Response Meta data Empty';
                setResponseOnMetadataCallback(responseModel);
            }
        }, requestModel);
        
        this.builder.topListHistoryStartRequest(this.id, requestModel);
    }

    public stopTopListHistory() {
        this.stop();
    }

    public unsubscribe() {
        this.stop();
    }

    private stop() {
        if (this.cancelToken) {
            this.cancelToken();
            this.cancelToken = null;
        }
        this.builder.topListHistoryStopRequest(this.id);
    }
}