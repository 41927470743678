/**
 * Request chart style data.
 *
 * This code was copied and translated from
 * https://github.com/TI-Pro/TIPro/blob/development/TIProGUI/Charting/Charts.cs
 * https://github.com/TI-Pro/TIPro/blob/39adbdb3656444075a2373f022210f2fc110b128/TIProGUI/Charting/Charts.cs#L16522
 */

import {
  csvStringToArray,
  getAttribute,
  makePromise,
  parseXml,
} from "phil-lib/misc";
import { TclListable, tclList } from "phil-lib/tcl";
import {COMMAND, CancelToken, Connection, ServerCommand, decodeServerTime, encodeDate } from "../services/connection.client";
import {
  Grid,
  GridRequestInfo,
  Options,
  SingleGridResponse,
} from "../javascript-api/single-grid-request";
import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";

/**
 * We are creating a string that will be decoded in createPrototypeCmd() on the server side:
 * https://github.com/TI-Pro/cpp_alert_server/blob/742ed11edbb5ea06e9990c686d2bec12a4a10620/source/tikiller/TclGridObjectWrapper.C#L107
 * @param grid The formulas to compute.  These are often displayed as columns.
 * @param options Which candles to grab.  5 minute?  Weekly?  Pre-market?  Etc.
 * @returns A description of the prototype suitable for a lot of TIQ API calls.
 */
export function encodePrototype(grid: Grid, options: Options): string {
  /**
   * A list of alternating names and values.
   */
  const optionsList: TclListable[] =
    // The default on the server was unpacked because that seemed like a
    // good idea when I first created the server.  In practice people
    // always use pack.  Newer server prototypes totally get rid of the
    // unpacked option because that simplifies a lot of code.
    // This JavaScript API completely hides the unpacked option.
    ["pack", 1];
  function add(name: string, value: number | string | undefined) {
    if (value !== undefined) {
      optionsList.push(name, value);
    }
  }
  add("row_count", options.rowCount);
  if (options.atTime) {
    optionsList.push("at_time", encodeDate(options.atTime));
  }
  switch (options.timeFrame) {
    case "daily": {
      add("candle_size", options.candleSize);
      break;
    }
    case "intraday": {
      add("minutes_per_candle", options.minutesPerCandle);
      add("start_offset", options.startOffset);
      add("end_offset", options.endOffset);
      add("first_candle_size", options.firstCandleSize);
      break;
    }
    default: {
      throw new Error(
        `Invalid options.timeFrame: "${JSON.stringify(options)}"`
      );
    }
  }
  const toEncode: TclListable[] = [options.timeFrame, grid, optionsList];
  return tclList(toEncode);
}

// TODO I copied this whole function from top-list-request.ts.  Need a good
// solution that can be shared.
function serverLogicFailure() {
  // This is a bit of a sledgehammer.  This is a good thing to do when you
  // see a nonsense reply from the server.  The assumption is that this
  // condition is rare and that it probably represents some serious
  // communications error.  Or maybe there is one bad server in the pool, and
  // next time I'll get hooked up to a better one.  In any case, force a
  // disconnect from the server and let the normal reconnect logic do its
  // thing.
  //
  // My only concern is that there is some problem that doesn't go away when
  // we reconnect.  What if there is something wrong with the top list code,
  // and the server keeps sending the same thing and the client keeps rejecting
  // it?  Then we might make all of the main program unusable when only one
  // feature is flawed.
  //
  // I considered canceling and resending just this one request.  That seemed like it
  // might cause more problems than it solves.  It makes the code much more
  // complicated and hard to test.
  //
  // TODO I suppose a delay with exponential back off would help.  The first
  // time we see this we pause one second before resetting.  If we have to
  // retry a second time, we pause 2 seconds.  Doubling each time.  And be
  // sure to stop retrying if and when the main program cancels the request.
  // This seems like a nice compromise.
  Connection.getInstance().softReset();
}

async function singleGridRequestBase(
  symbols: Set<string>,
  info: GridRequestInfo
): Promise<Map<string, SingleGridResponse>> {
  
  const messageToServer = [
    [COMMAND, "candles_command"],
    ["subcommand", "chart_data"],
    ["prototype", info.prototype],
    ["start_before", info.startBefore],
    ["at_least_from", info.oldestAnchor],
    ["since", info.sinceDate],
    ["row_count", info.rowCount],
    ["extra_row_count", info.extraRowCount],
  ] as ServerCommand;

  const result = new Map<string, SingleGridResponse>();
  while (symbols.size > 0) {
    messageToServer.push(["symbols", tclList(symbols)]);
    const done = makePromise();
    const cancelRequest = Connection.getInstance().sendWithStreamingResponse(
      messageToServer,
      (response: any) => {
        if (response === undefined) {
          TraceLoggingHelpers.log("Retrying because of server disconnect.");
          // TODO are we really RE-trying?  Maybe we succeeded 5 minutes ago?
          done.resolve();
        } else {
          const xml = parseXml(response);
          if (!xml) {
            TraceLoggingHelpers.log("Retrying because of invalid xml.", response);
            serverLogicFailure();
            done.resolve();
          } else {
            const responseType = getAttribute("type", xml);
            switch (responseType) {
              case "result": {
                const symbol = getAttribute("symbol", xml);
                if (symbol === undefined || !symbols.has(symbol)) {
                  TraceLoggingHelpers.log("problem with symbol", symbol);
                } else {
                  const body = xml.textContent ?? "";
                  const grid = csvStringToArray(body);
                  result.set(symbol, { /*debug: body,*/ grid });
                  symbols.delete(symbol);
                  if (symbols.size == 0) {
                    // Success!
                    done.resolve();
                    cancelRequest();
                  }
                  TraceLoggingHelpers.log(`Found ${symbol}, ${symbols.size} to go.`);
                }
                break;
              }
              case "done": {
                TraceLoggingHelpers.log("got 'done' before getting all stock data");
                done.resolve();
                break;
              }
              default: {
                // Default is to do nothing.  Sometimes the server will send out
                // debug messages or similar.
                TraceLoggingHelpers.log("responseType", responseType);
                break;
              }
            }
          }
        }
        // TraceLoggingHelpers.log(responseString, "SingleGridRequest");
      }
    );
    await done.promise;
    cancelRequest();
  }
  //TODO parse the csv file;
  return result;
}

export function singleGridRequestMap(
  symbols: Iterable<string>,
  info: GridRequestInfo
): Promise<Map<string, SingleGridResponse>> {
  return singleGridRequestBase(new Set(symbols), info);
}

export async function singleGridRequestOne(
  symbol: string,
  info: GridRequestInfo
): Promise<SingleGridResponse> {
  const allResults = await singleGridRequestBase(
    new Set<string>().add(symbol),
    info
  );
  const result = allResults.get(symbol);
  if (!result) {
    throw new Error("wtf");
  } else {
    return result;
  }
}

export async function singleGridRequestArray(
  symbols: string[],
  info: GridRequestInfo
): Promise<SingleGridResponse[]> {
  const allResults = await singleGridRequestBase(new Set(symbols), info);
  const result = symbols.map((symbol) => {
    const singleResult = allResults.get(symbol);
    if (!singleResult) {
      throw new Error("wtf");
    }
    return singleResult;
  });
  return result;
}
