import { parseXml } from "phil-lib/misc";

import {COMMAND, Connection, ServerCommand} from "../../services/connection.client";

export async function histogramRequest(configs: string): Promise<Element | undefined> {

    const messageToServer = [
        [COMMAND, "symbol_count_request"],
        ["long_form", configs]
    ] as ServerCommand;

    var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
    
    const xml = parseXml(response);
   
    return xml;
  
}