import { SingleGridResponse } from "../javascript-api/single-grid-request";
import { ClientResponseModel } from "../lib-export/everything";
import { singleGridRequestMap } from "../tipro-data/single-grid-request";
import { ChartDataRequestModel } from "./models/chart-data/chart-data-request.model";

export class ChartDataClient {
    constructor() {}

    public async getChartData(requestModel: ChartDataRequestModel): Promise<ClientResponseModel<Map<string, SingleGridResponse>>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Map<string, SingleGridResponse>>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';

        //     return responseModel;
        // }

        // TraceLoggingHelpers.log('getChartData ', requestModel)
        // TraceLoggingHelpers.log('requestModel.getAmbiancePrototype() ', requestModel.getAmbiancePrototype())
        const response = await singleGridRequestMap(requestModel.symbols, {
            prototype: requestModel.getAmbiancePrototype(),
            rowCount: requestModel.rowCount,
            startBefore: requestModel.startBefore,
            extraRowCount: requestModel.extraRowCount,
            sinceDate: requestModel.sinceDate
        });

        const model = new ClientResponseModel<Map<string, SingleGridResponse>>();
        model.isSuccess = true;
        model.data = response;

        return model;
    }
}