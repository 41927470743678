import { DateTime } from "luxon";

export class AiStrategy {



    //Properties Set On Get Strategies Response
    public name: string | undefined;
    public winRate: number | undefined;
    public profitFactor: number | undefined;
    public config: string | undefined;
    public isLong: boolean  = false;
    public timeFrame: string | undefined;
    public totalTrades: number = 0;
    public maxTradesPerDay: number = 0;
    public cloudLink: string | undefined;
    public profitPerDay: Array<number> | undefined;
    //Need to add Thermograph
    public optimizedDate: Date | undefined;
    public creationDate: Date | undefined;
    public segmentCode: string | undefined;
    public segmentName: string | undefined;


    //Calculated Properties using Strategy Trades
    // public lastSymbol: string | undefined;
    // public lastSignalPrice: number | undefined;
    // public lastSignalTime: Date | undefined;
    // public lastStopPrice: number | undefined;
    // public lastSmartStop: number | undefined;


}