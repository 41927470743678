export class TopListHistoryDataRequestModel {
    public config = '';
    public resultCount?: number;
    public saveToMru = false;

    public retryCount: number = 0;
    //public retryMillisecondsThreshold: number = 600000; // 10 minutes, which basically disables retries 
    public retryMillisecondsThreshold: number = 2000; // retry after 2 seconds 
    public canceledRetryThreshold: number = 3;
    public isRetryRequest = false;

    public clone(){
        const clonedRequest = new TopListHistoryDataRequestModel();
        clonedRequest.config = this.config;
        clonedRequest.saveToMru = this.saveToMru;
        clonedRequest.retryCount = this.retryCount;
        clonedRequest.retryMillisecondsThreshold = this.retryMillisecondsThreshold;
        clonedRequest.canceledRetryThreshold = this.canceledRetryThreshold;
        clonedRequest.isRetryRequest = this.isRetryRequest;
        return clonedRequest;
    }
    
    public removeConfigStringPropertyIfPropertyNameSet(modelPropertyName: string, configStringName: string) {
        const configStringProperty = `&${configStringName}=`;
        const modelAsAny = this as any;
        if (modelAsAny[modelPropertyName] && this.config && this.config.indexOf(configStringProperty) > -1) {
            // Split the string into an array based on '&'
            const parts = this.config.split('&');

            // Filter out the part you want to remove
            const filteredParts = parts.filter(part => part.indexOf(configStringName) == -1);

            // Join the filtered parts back into a single string using '&'
            this.config = filteredParts.join('&');
        }
    }

    public addPropertyToConfigStringIfPropertyNameSet(modelPropertyName: string, configStringName: string) {
        const configStringProperty = `&${configStringName}=`;
        const modelAsAny = this as any;
        if (modelAsAny[modelPropertyName] && this.config && this.config.indexOf(configStringProperty) == -1) {
            this.config += configStringProperty + modelAsAny[modelPropertyName];
        }
    }
}