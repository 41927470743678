import { holidayRequest } from "./api-request-builders/holiday-request.builder";
import { HolidayAlertsXMLToResponseModelMapper } from "./mappers/holiday-xml-to-response-model.mapper";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { HolidayModel } from "./models/holiday/holiday.model";

export class HolidayClient {
    constructor() {}

    public async getHoliday(): Promise<ClientResponseModel<Array<HolidayModel>>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Array<HolidayModel>>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     return responseModel;
        // }  

        const response = await holidayRequest();
        
        const model = new ClientResponseModel<Array<HolidayModel>>(); 
        if (!response) {
            return model;
        }

        const result = HolidayAlertsXMLToResponseModelMapper.map(response);
        
        model.isSuccess = true;
        model.data = result;

        return model;
    }
}