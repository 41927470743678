import { ProfitBar } from "./profit-bar";

export class BrokerAccount {
    public Name:string | undefined = undefined;
    public UserId:string | undefined = undefined;
    public AccountId:string | undefined = undefined;
    public AccountNumber:string | undefined = undefined;
    public Value:number = 0;
    public BuyingPower:number = 0;
    public AvailableFunds:number = 0;
    public UnrealizedProfit:number = 0;
    public RealizedProfit:number = 0;
    public Created:Date | undefined = undefined;

    
    public TodayProfit:number | undefined = undefined;
    public TodayProfitPercent:number | undefined = undefined;

    public ProfitBars:Array<ProfitBar>;

    constructor() {
        this.ProfitBars = new Array<ProfitBar>();
    }

}
