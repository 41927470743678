import { AppSettings } from "../app-settings"
import { ConnectionSettings } from "../connection-settings";
//import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";

export type Callbacks = {
  onOpen(): void;
  onClose(code: number, reason: string, wasClean: boolean): void;
  onError(): void;
  onMessage(bytes: Uint8Array): void;
};

/**
 * Source:  https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#properties
 */
const WEB_SOCKET_NORMAL_CLOSURE = 1000;

export class WebSocketTunnelConnection {
  #connection: WebSocket;

  close() {
    try {
      this.#connection.close(WEB_SOCKET_NORMAL_CLOSURE, "Requested by user.");
    } catch {}
  }

  constructor(callbacks: Callbacks) {
    const appSettings = AppSettings.getInstance();
    const connectionSettings = ConnectionSettings.getInstance();

    //TraceLoggingHelpers.log('AppSettings:', appSettings);
    //TraceLoggingHelpers.log('ConnectionSettings:', connectionSettings);
    
    let url = "wss://" + appSettings.tiServicesHost +
    "/TradeIdeasWebService/v1/WebSocketTunnel/connect?applicationId=" +
    connectionSettings.applicationId;

    //This will let us know what page the connection is made from   
    url += "&location=" + encodeURIComponent(connectionSettings.location ?? "");

    if (connectionSettings.destination)
      url += '&dest=' + connectionSettings.destination;

      if (connectionSettings.userName)
      url += '&userName=' + connectionSettings.userName;
    
    if(connectionSettings.jwt)
      url += "&access_token=" + connectionSettings.jwt;

      //We can also override on the server 
      url += "&throttleMS=2000";


    //TraceLoggingHelpers.log('Connection String:', url);
    
    const connection = new WebSocket(url);
    this.#connection = connection;
    connection.binaryType = "arraybuffer";

    connection.onmessage = (event: MessageEvent<unknown>) => {
      if (event.data instanceof ArrayBuffer) {
        callbacks.onMessage(new Uint8Array(event.data));
      } else {
        console.warn("Unknown message data type", event);
      }
    };

    connection.onopen = () => {
      console.log("open");
      callbacks.onOpen();
    };

    connection.onerror = () => {
      console.log("error");
      callbacks.onError();
    };

    connection.onclose = (event: CloseEvent) => {
      console.log("close", event);
      callbacks.onClose(event.code, event.reason, event.wasClean);
    };
  }

  sendMessage(message: string | ArrayBufferView) {
    this.#connection.send(message);
  }
}
