//The Regular MDN DOMParser does not work from a SharedWorker so we've 
//switched to xmldom which seems to have the same interface.  

import xmldom, {DOMParser} from "xmldom";

export type XmlStatus = | { parsed: Document; error?: undefined } | { parsed?: undefined; error: HTMLElement };

export function parseXml2(bytes : string | undefined) : Element | undefined
    {
        if (bytes === undefined)
        {
            return undefined;
        }
        else
        {
            const parsed = testXml2(bytes);
            return parsed?.parsed?.documentElement;
        }
}

export function testXml2(xmlStr: string): XmlStatus {
    const parser = new xmldom.DOMParser();
    const dom = parser.parseFromString(xmlStr, "application/xml");
    for (const element of Array.from( dom.getElementsByTagName("parsererror"))) {
      if (element instanceof HTMLElement) {
        // Found the error.
        return { error: element };
      }
    }
    // No errors found.
    return { parsed: dom };
}
    
