// symbol,type,timet,high,low,previous_high,previous_low,previous_high_timet,previous_low_timet,entry_timet,entry_price,
//last,days_profitable,total_days,total_change,total_change_percent,is_long,mfe,mae

export class AiLongTermStrategyTrade {
                                                       //CSV Field
    public symbol: string | undefined;                 //symbol 
    public type: string | undefined;                   //type
    public time: Date | undefined;                     //timet
    public high: number | undefined;                   //high
    public low: number | undefined;                    //low
    public previousHigh: number | undefined;           //previous_high 
    public previousLow: number | undefined;            //previous_low 
    public previousHighTime: Date | undefined;         //previous_high_timet
    public previousLowTime: Date | undefined;          //previous_low_timet
    public entryTime: Date | undefined;                //entry_timet
    public entryPrice: number | undefined;             //entry_price
    public last: number | undefined;                   //last
    public daysProfitable: number | undefined;         //days_profitable
    public totalDays: number | undefined;              //total_days
    public totalChange: number | undefined;            //total_change
    public totalChangePercent: number | undefined;     //total_change_percent
    public isLong: boolean  = false;                   //is_long
    public mfe: number | undefined;                    //mfe 
    public mae: number | undefined;                    //mae

}