import { parseXml } from "phil-lib/misc";
import { CancelToken, COMMAND, Connection, ServerCommand } from "../connection.client";
import { HaltedRequestModel } from "../models/halted/halted-request.model";

export function haltedListenRequest(requestModel: HaltedRequestModel, callback: Function): CancelToken {
    const messageToServer = [
        [COMMAND, "haltresume"],
        ["subcommand", "haltresume_add_listener"],
        ["symbol", requestModel.symbol]
    ];

    return Connection.getInstance().sendWithStreamingResponse(messageToServer as ServerCommand, (response: string) => {
        const xml = parseXml(response);
        callback(xml);
    });
}