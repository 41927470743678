export class SymbolListResponseModel {
    public internalCode = '';
    public listDescription = '';
    public listName = '';

    constructor(data?: any) {
        if (data) {
            this.internalCode = data.internalCode;
            this.listDescription = data.listDescription;
            this.listName = data.listName;
        }
    }
}