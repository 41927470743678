import { MetaData } from "../../tipro-data/top-list-request";
import { StreamingAlertsMetaDataResponeModel } from "../models/streaming-alerts/streaming-alerts-metadata-response.model";
import { StreamingAlertsResponeModel } from "../models/streaming-alerts/streaming-alerts-response.model";

export class StreamingAlertsXMLToResponseModelMapper {
    public static map(windowId: string, rawXML: Element): Array<StreamingAlertsResponeModel> {
        const result: Array<StreamingAlertsResponeModel> = [];
        if (rawXML.children?.length) {
            const children = rawXML?.children?.item(0)?.children;
            if (children) {
                for (const item of Array.from(children)) {
                    const id = item.getAttribute('ID');
                    if (id == windowId) {
                        const matchChildren = item.children
                        if (matchChildren) {
                            for (const child of Array.from(matchChildren)) {
                                const model: any = {};
                                for (const attr of Array.from(child.attributes)) {
                                    model[attr.name] = attr.value;
                                }

                                model.fromAlertHistory = false;
                                result.push(model);
                            }
                        }
                    }
                }
            }
        }
        return result;
    }

    public static mapMetaData(rawXML: Element): MetaData {
        const result: any = {};

        if (rawXML.children?.length) {
            const first = rawXML?.children?.item(0);
            if (first) {
                result.ALERTS = {
                    COLUMNS: [],
                    SHORT_FORM: first.getAttribute('SHORT_FORM'),
                    WINDOW_NAME: first.getAttribute('WINDOW_NAME')
                };
                const children = first.children?.item(0)?.children;
                if (children) {
                    for (const item of Array.from(children)) {
                        const model: any = {};

                        model.NAME = item.tagName;
                        
                        for (const attr of Array.from(item.attributes)) {
                            model[attr.name] = attr.value;
                        }
                        
                        result.ALERTS.COLUMNS.push(model);
                    }
                }
            }
        }
        
        const metaData: MetaData = { metaDataXml: rawXML.outerHTML, metaDataJson: result };
        return metaData;
    }
}