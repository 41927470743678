import { xml2js } from 'xml-js';
import { CloudLinkResponseModel } from "../models/cloud-link/cloud-link-response.model";
import { TIUtilities } from '../utilities/ti-utilities';
import { parseXml } from 'phil-lib/misc';
export class ImportCloudLinkXMLToResponseModelMapper {
    public static map(rawXML: Element): CloudLinkResponseModel | null {
        if (rawXML.children?.length) {
            const model = new CloudLinkResponseModel();
            const content = TIUtilities.removeCData(rawXML.children.item(0)?.innerHTML ?? '');
            
            model.xmlData = parseXml(content) ?? null; 

            if (model.xmlData) {
                const str = new XMLSerializer().serializeToString(model.xmlData);
                const jsonModel = xml2js(str);
                model.jsonData = jsonModel;
            }

            return model;
        }

        return null;
    }
}