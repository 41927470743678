import { followPath, getAttribute } from "phil-lib/misc";
import { decodeServerTime } from "../../services/connection.client";
import { Data, wireNameToColumnFormula } from "../../tipro-data/top-list-request";

export class TopListHistoryXMLToResponseModelMapper {
    public static map(response: any): Data | null {
        const rawXML: Element = response.xml;
        const result: Map<string, string>[] = [];
        if (rawXML) {
            if (rawXML.children?.length) {
                const children = rawXML.children.item(0)?.children;
                if (children?.length) {
                    for (const child of Array.from(children)) {
                        let keyValuePair = new Map<string, string>();
                        for (const attr of Array.from(child.attributes)) {
                            let fieldName = wireNameToColumnFormula(attr.name);
                            if (fieldName) {
                                keyValuePair.set(fieldName, attr.value);
                            }
                        }
                        result.push(keyValuePair);
                    }
                }
            }

        }

        const topListElement = followPath(rawXML, "TOPLIST");
        const data: any = {
            rows: result,
            canceledRetryThreshold: response.canceledRetryThreshold,
            canceledRetryThresholdElapsed: response.canceledRetryThresholdElapsed,
            requestRetryCount: response.requestRetryCount,
            requestThatNeverReturned: response.requestThatNeverReturned
        };

        if (topListElement) {            
            data.start = decodeServerTime(
                getAttribute("START_TIME", topListElement)
            );
            data.end = decodeServerTime(
                getAttribute("END_TIME", topListElement)
            );
        }

        return data;
    }
}