import { parseXml } from "phil-lib/misc";
import {COMMAND, CancelToken, Connection, ServerCommand } from "../../services/connection.client";

//import { COMMAND, CancelToken, ResponseFromServer, createMessageToServer, responseToString } from "../../server-connection/talk-with-server";
import { IdGenerator } from "../models/helpers/id-generator";
import { CreatePriceAlertRequestModel } from "../models/price-alert/create-price-alert-request.model";
import { TIUtilities } from "../utilities/ti-utilities";

type PriceAlertCallback = {
  id: string;
  callback: Function;
};

export class PriceAlertRequestBuilder {

  private static instance: PriceAlertRequestBuilder;
  public static getInstance(): PriceAlertRequestBuilder {
    if (!this.instance) {
      this.instance = new PriceAlertRequestBuilder();
    }

    return this.instance;
  }

  private callbackListeners: Array<PriceAlertCallback> = [];

  public priceAlertListenRequest(clientId: string, callback: Function): CancelToken {
    

    const messageToServer = [
      [COMMAND, "limit_alert_command"],
      ["subcommand", "la_add_listener"]
    ];

    this.callbackListeners.push({ id: clientId, callback: callback });
    return Connection.getInstance().sendWithStreamingResponse(messageToServer as ServerCommand, (response: string) => {

      const xml = parseXml(response);
      
      for (const callbackListener of this.callbackListeners) {
        callbackListener.callback(xml);
      }
    });
  }

  public async priceAlertStopRequest(clientId: string) {
    const callBackListenerIndex = this.callbackListeners.findIndex((x: PriceAlertCallback) => x.id == clientId);
    if (callBackListenerIndex > -1) {
      this.callbackListeners.splice(callBackListenerIndex, 1);
    }

    if (!this.callbackListeners.length) {
    
      Connection.getInstance().sendWithNoResponse([
        [COMMAND, "limit_alert_command"],
        ["subcommand", "la_remove_listener"]
      ]);
    }
  }

  public async createPriceAlertRequest(model: CreatePriceAlertRequestModel): Promise<Element | undefined> {
    const protoType = this.getAddPriceAlertPrototype(model, true);
    
    const messageToServer = [
      [COMMAND, "limit_alert_command"],
      ["subcommand", "la_add_alert"],
      ["options", protoType]
    ];

    const outboxResponse = Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand);
    const response = await outboxResponse.promise;
    const xml = parseXml(response);
    return xml;
  }

  private getAddPriceAlertPrototype(limitAlert: CreatePriceAlertRequestModel, addClientId: boolean): string {
    let toReturn = "symbol " + limitAlert.symbol
      + " price " + limitAlert.price
      + " is_long " + (limitAlert.isLong ? "1" : "0");
    toReturn += " long_after " + (limitAlert.longAfter ? "1" : "0");
    toReturn += " after_hours " + (limitAlert.afterHours ? "1" : "0");

    if (limitAlert.invalidPrice)
      toReturn += " invalid_price " + limitAlert.invalidPrice;
    if (limitAlert.expires)
      toReturn += " expires " + TIUtilities.toTimeT(limitAlert.expires);
    if (limitAlert.triggered)
      toReturn += " triggered " + TIUtilities.toTimeT(limitAlert.triggered);

    toReturn += " created " + TIUtilities.toTimeT(limitAlert.created);
    toReturn += " updated " + TIUtilities.toTimeT(limitAlert.updated);

    if (limitAlert.id)
      toReturn += " id " + limitAlert.id;

    if (limitAlert.status)
      toReturn += " status " + limitAlert.status;

    if (addClientId)
      toReturn += " client_id " + IdGenerator.NewId();

    let escapedNotes = limitAlert.notes;
    escapedNotes = escapedNotes.replace(/{/g, "\\{");
    escapedNotes = escapedNotes.replace(/}/g, "\\}");

    toReturn += " notes {" + escapedNotes + "}";
    return toReturn;
  }

  public async deletePriceAlertRequest(limitAlertId: string): Promise<Element | undefined> {

    const messageToServer = [
      [COMMAND, "limit_alert_command"],
      ["subcommand", "la_remove_alert"],
      ["id", limitAlertId]
    ];

    const outboxResponse = Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand);
    const response = await outboxResponse.promise;
    const xml = parseXml(response);

    return xml;
  }
}