import { parseTimeT } from "phil-lib/misc";
import { AlertHistoryClient } from "./alert-history.client";
import { StreamingAlertRequestBuilder } from "./api-request-builders/streaming-alerts-request.builder";
import { StreamingAlertsXMLToResponseModelMapper } from "./mappers/streaming-alerts-xml-to-response-model.mapper";
import { AlertHistoryRequestModel } from "./models/alert-history/alert-history-request.model";
import { AlertHistoryResponeModel } from "./models/alert-history/alert-history-response.model";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { DateHelpers } from "./models/helpers/date-helpers";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";
import { StreamingAlertsRequestModel } from "./models/streaming-alerts/streaming-alerts-request.model";
import { StreamingAlertsResponeModel } from "./models/streaming-alerts/streaming-alerts-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { MetaData } from "../tipro-data/top-list-request";

export class StreamingAlertsClient {

    private streamingAlertBuilder: StreamingAlertRequestBuilder;
    private alertHistoryService: AlertHistoryClient | null = null;
    private id = IdGenerator.NewId();
    private nextId: string | null = null;
    private repeatDataDict: Record<string, Date> = {};

    constructor() {
        this.streamingAlertBuilder = StreamingAlertRequestBuilder.getInstance();
    }

    public getStreamingAlerts(requestModel: StreamingAlertsRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback?: Function) {
        this.start(requestModel, setResponseOnDataCallback, setResponseOnMetadataCallback);
    }

    public subscribe(requestModel: StreamingAlertsRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback?: Function): StreamingAlertsClient {
        this.start(requestModel, setResponseOnDataCallback, setResponseOnMetadataCallback);
        return this;
    }

    private firstCancelSubscriptionFunction: any;
    private start(requestModel: StreamingAlertsRequestModel, setResponseOnDataCallback: Function, setResponseOnMetadataCallback?: Function) {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Array<StreamingAlertsResponeModel>>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     setResponseOnDataCallback(responseModel);
        //     return;
        // }

        const endTime = new Date();
        endTime.setHours(0, 0, 0, 0);

        this.nextId = null;

        const alertHistoryRequestModel = new AlertHistoryRequestModel();
        alertHistoryRequestModel.config = requestModel.config;
        alertHistoryRequestModel.maxCount = 1;
        alertHistoryRequestModel.endTime = endTime;

        this.alertHistoryService = new AlertHistoryClient().subscribe(alertHistoryRequestModel, (response: ClientResponseModel<Array<AlertHistoryResponeModel>>) => {
    
            if (response.isSuccess && response.data && requestModel.noRepeat) {
                const processedRepeatData = this.processRepeatData(requestModel, response.data);
                if (processedRepeatData.length) {
                    response.data = processedRepeatData;
                    setResponseOnDataCallback(response);
                }
            } else {
                setResponseOnDataCallback(response);
            }
        });

        const currentCancelSubscriptionFunction = this.streamingAlertBuilder.streamingAlertsListenRequest(this.nextId, this.id, (responseXML: Element | null, nextId: string | null) => {
            // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
            //     const responseModel = new ClientResponseModel<Array<StreamingAlertsResponeModel>>();
            //     responseModel.isSuccess = false;
            //     responseModel.message = 'Not logged in, please Login';
            //     setResponseOnDataCallback(responseModel); 
            //     return;
            // }

            this.nextId = nextId;

            const responseModel = new ClientResponseModel<Array<StreamingAlertsResponeModel>>();
            if (responseXML) {
                responseModel.isSuccess = true;

                const stremingAlertsData = StreamingAlertsXMLToResponseModelMapper.map(this.id, responseXML);
                if (requestModel.noRepeat) {
                    const processedRepeatData = this.processRepeatData(requestModel, stremingAlertsData);
                    // TraceLoggingHelpers.log('processedRepeatData ', processedRepeatData, ' repeatDataDict ', this.repeatDataDict)
                    if (processedRepeatData.length) {
                        responseModel.data = processedRepeatData;
                        setResponseOnDataCallback(responseModel);
                    }
                } else {
                    responseModel.data = stremingAlertsData;
                    setResponseOnDataCallback(responseModel);
                }
            } else {
                responseModel.message = 'Response XML Empty';
                setResponseOnDataCallback(responseModel);
            }
        });

        if (!this.firstCancelSubscriptionFunction) {
            // The firstCancelSubscriptionFunction is used for stopStreamingAlerts
            this.firstCancelSubscriptionFunction = currentCancelSubscriptionFunction;
        } else {
            // This happens when you stop and start, since the firstCancelSubscriptionFunction is already set. 
            // The streamingAlertBuilder is creating new callback instances, this is not cancelling the current callback, it's cancelling the previous callback
            currentCancelSubscriptionFunction();
        }

        this.streamingAlertBuilder.streamingAlertsStartRequest(this.id, requestModel).then((responseXML: Element | undefined) => {
            const metadataResponseModel = new ClientResponseModel<MetaData>();
            if (responseXML) {
                const streamingAlertsMetaData = StreamingAlertsXMLToResponseModelMapper.mapMetaData(responseXML);
                metadataResponseModel.isSuccess = true;
                metadataResponseModel.data = streamingAlertsMetaData;
                if (setResponseOnMetadataCallback)
                    setResponseOnMetadataCallback(metadataResponseModel);
            } else {
                metadataResponseModel.message = 'Response XML Empty';
                if (setResponseOnMetadataCallback)
                    setResponseOnMetadataCallback(metadataResponseModel);
            }
        });
    }

    public stopStreamingAlerts() {
        this.stop();
    }

    public unsubscribe() {
        this.stop();
    }

    private stop() {
        // if (this.cancelToken) {
        //     this.cancelToken();
        //     this.cancelToken = null;
        // }

        this.streamingAlertBuilder.streamingAlertsStopRequest(this.id);

        this.alertHistoryService?.unsubscribe();
    }

    private processRepeatData(requestModel: StreamingAlertsRequestModel, stremingAlertsData: Array<StreamingAlertsResponeModel>): Array<StreamingAlertsResponeModel> {
        if (!stremingAlertsData.length) {
            return [];
        }

        const result: Array<StreamingAlertsResponeModel> = [];

        for (const stremingAlertData of stremingAlertsData) {
            const data: any = stremingAlertData;
            const symbol = data['c_D_Symbol'];
            const alertTime = parseTimeT(data['c_D_Time']);

            if (!symbol || !alertTime) {
                continue;
            }

            //TODO: Create a NumberFunctions.HasValue(...) or NumberFunctions.LessThan(0)
            let allowRepeats = requestModel.repeatIntervalInMinutes == -1 || Number.isNaN(requestModel.repeatIntervalInMinutes) || requestModel.repeatIntervalInMinutes == undefined || requestModel.repeatIntervalInMinutes == null;
            if (allowRepeats) {
                result.push(data)
                this.repeatDataDict[symbol] = alertTime;

                TraceLoggingHelpers.log('New Alert for Symbol:', symbol);
                continue;
            }

            const symbolDoesNotExistInDictionary = !this.repeatDataDict[symbol];
            if (symbolDoesNotExistInDictionary) {
                result.push(data)
                this.repeatDataDict[symbol] = alertTime;
                TraceLoggingHelpers.log('New Alert for Symbol:', symbol);
            } else {
                const doNotRepeatEver = requestModel.repeatIntervalInMinutes == 0;
                if (doNotRepeatEver) {
                    TraceLoggingHelpers.log('No Repeat - Skipping Alert Symbol:', symbol);
                    continue;
                }

                const existingDictionaryTime = this.repeatDataDict[symbol];
                const minutesDifferent = DateHelpers.getMinutesDifferent(existingDictionaryTime, alertTime);
                // TraceLoggingHelpers.log('symbol: ', symbol, ' minutesDifferent ', minutesDifferent)

                const currentMinuteIsOutSideOfMinuteInterval = minutesDifferent >= requestModel.repeatIntervalInMinutes;
                if (currentMinuteIsOutSideOfMinuteInterval) {
                    result.push(data)
                    this.repeatDataDict[symbol] = alertTime;
                    TraceLoggingHelpers.log('Update Existing Alert for Symbol:', symbol);
                } else {
                    TraceLoggingHelpers.log('No Repeat - Skipping Alert Symbol:', symbol);

                    continue;
                }
            }
        }

        return result;
    }
}