import { HolidayCountry } from "../enums/holiday-country.enum";
import { DateHelpers } from "../helpers/date-helpers";

export class HolidayModel {
    public date: Date | null = null;

    /**
     * Is this holiday a full day?
     */
    public closed = false;

    /**
     * Is this holiday a half day?
     */
    public halfDay = false;
    
    /**
     * Country code for this holiday. Can be US or Canada
     */
    public country = HolidayCountry.US;
    

    constructor(holidayData?: any) {
        if (holidayData) {
            this.date = new Date(holidayData.date);
            this.closed = holidayData.closed;
            this.halfDay = holidayData.halfDay;
            this.country = holidayData.country;
        }
    }

    public toString(): string {
        let toReturn = '';
        if (this.date) {
            toReturn = DateHelpers.format('yyyy-mm-dd', this.date) + ' ' + this.country;
            if (this.halfDay)
                toReturn += " Half Day";
            if (this.closed)
                toReturn += " Market Holiday";
        }

        return toReturn;
    }
}