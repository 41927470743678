import { ChartIndicatorsRequestBuilder } from "./api-request-builders/chart-indicators-request.builder";
import { ChartIndicatorResponeModel } from "./models/chart-indicators/chart-indicator-response.model";
import { ClientResponseModel } from "./models/client-response/client-response.model";

export class ChartIndicatorsClient {
    private builder: ChartIndicatorsRequestBuilder;

    constructor() {
        this.builder = ChartIndicatorsRequestBuilder.getInstance();
    }

    public async getChartIndicators(): Promise<ClientResponseModel<Array<ChartIndicatorResponeModel>>> {
        const indicators = await this.builder.chartIndicatorRequest();
        const model = new ClientResponseModel<Array<ChartIndicatorResponeModel>>(); 
        if (indicators) {
            model.isSuccess = true;
            model.data = indicators;
        }

        return model;
    }
}