import { parseXml, testXml } from "phil-lib/misc";
import { ClientResponseModel } from "../lib-export/everything";
import { importCloudLinkRequest, saveToCloudRequest } from "./api-request-builders/cloud-link-request.builder";
import { ImportCloudLinkXMLToResponseModelMapper } from "./mappers/import-cloud-link-xml-to-response-model.mapper";
import { CloudLinkResponseModel } from "./models/cloud-link/cloud-link-response.model";
import { SaveToCloudRequestModel } from "./models/cloud-link/save-to-cloud-request.model";
import { SaveToCloudResponseModel } from "./models/cloud-link/save-to-cloud-response.model";

export class CloudLinkClient {
    constructor() {}

    public async importCloudLink(cloudLink: string): Promise<ClientResponseModel<CloudLinkResponseModel>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<CloudLinkResponseModel>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     return responseModel;
        // }        
        
        const response = await importCloudLinkRequest(cloudLink);
        
        const model = new ClientResponseModel<CloudLinkResponseModel>();
        if (!response) {
            return model;
        }

        const result = ImportCloudLinkXMLToResponseModelMapper.map(response);
        
        model.isSuccess = true;
        model.data = result;
        
        return model;
    }


    public async saveToCloud(saveToCloudRequestModel: SaveToCloudRequestModel): Promise<ClientResponseModel<SaveToCloudResponseModel>> {

        if (!saveToCloudRequestModel.layoutXml){
            const responseModel = new ClientResponseModel<SaveToCloudResponseModel>();
            responseModel.isSuccess = false;
            responseModel.message = 'Unable to save this layout to the cloud.  Layout is empty.';
            return responseModel;                
        }
    
        if (saveToCloudRequestModel.layoutXml.length > 500000){
            const responseModel = new ClientResponseModel<SaveToCloudResponseModel>();
            responseModel.isSuccess = false;
            responseModel.message = 'Unable to save this layout to the cloud.  It is too big.';
            return responseModel;                
        }

    
        const testedXml = testXml(saveToCloudRequestModel.layoutXml);
        if (testedXml.error){
            const responseModel = new ClientResponseModel<SaveToCloudResponseModel>();
            responseModel.isSuccess = false;
            if (testedXml.error.textContent){
                responseModel.message = 'Error parsing layoutXml: ' + testedXml.error.textContent;
            }
            else{
                responseModel.message = 'Error parsing layoutXml, please check the Xml structure'
            }
            
            return responseModel;                
        }        

        

        const response = await saveToCloudRequest(saveToCloudRequestModel);
        
        const model = new ClientResponseModel<SaveToCloudResponseModel>();
        if (!response) {
            return model;
        }

        const cloudLinkString = response.getAttribute("CODE");

        const result = new SaveToCloudResponseModel();
        result.cloudLink = cloudLinkString;
        
        model.isSuccess = true;
        model.data = result;
        
        return model;
    }    

    
}