import { HistogramItemResponseModel } from "../models/histogram/histogram-item-response.model";
import { HistogramResponseModel } from "../models/histogram/histogram-response.model";

export class HistogramXMLToResponseModelMapper {
    public static map(rawXML: Element, filterString: string): HistogramResponseModel | null {
        let result: HistogramResponseModel | null = null;
        if (rawXML.children?.length) {
            const node = rawXML.children.item(0)?.children.item(0);
            if (node) {
                if (node.getAttribute("CODE") == filterString) {
                    const totalCount = parseInt(node.getAttribute("TOTAL") ?? '');
                    result = new HistogramResponseModel();
                    result.totalCount = totalCount;
                    if (node.children?.length) {
                        for (const child of Array.from(node.children)) {
                            const item = new HistogramItemResponseModel();
                            item.value = parseFloat(child.getAttribute("VALUE") ?? '0');
                            item.cumulativeLessCount = parseInt(child.getAttribute("LESS") ?? '0');
                            item.cumulativeMoreCount = parseInt(child.getAttribute("NOT_MORE") ?? item.cumulativeLessCount + '');

                            result.items.push(item);
                        }
                    }
                }
            }
        }
        return result;
    }
}