import { companyProfileRequest } from "./api-request-builders/company-profile-request.builder";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { CompanyProfileResponseModel } from "./models/company-profile/company-profile-response.model";

export class CompanyProfileClient {
    constructor() {}

    public async getCompanyProfile(symbol: string): Promise<ClientResponseModel<CompanyProfileResponseModel>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<CompanyProfileResponseModel>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     return responseModel;
        // }      

        // TraceLoggingHelpers.log('getCompanyProfile ', symbol)
        const symbolUpperCase = symbol.toUpperCase();
        const response = await companyProfileRequest(symbolUpperCase);
        
        const model = new ClientResponseModel<CompanyProfileResponseModel>();
        if (!response || !response.children.length) {
            return model;
        }

        // TraceLoggingHelpers.log('getCompanyProfile response', response)

        const responseModel = new CompanyProfileResponseModel();
        const profileNode = response.children[0];
        responseModel.country = profileNode.getAttribute('COUNTRY') ?? '';
        responseModel.website = profileNode.getAttribute('WEBSITE') ?? '';
        responseModel.symbol = profileNode.getAttribute('SYMBOL') ?? '';
        responseModel.summaryCDATA = profileNode.innerHTML
        responseModel.summary =  profileNode.textContent ?? '';

        model.isSuccess = true;
        model.data = responseModel;

        return model;
    }
}