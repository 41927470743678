import {  encodeDate} from "../../../services/connection.client";
export class ProfitBar {

    public StartDate:Date | undefined;
    public StartTimeT:number | undefined;
    public AdjustedClose: number | undefined;
    public Shares:number;
    public Profit:number;

    constructor(startDate:Date | undefined, shares: number, profit: number, adjustedClose: number) {
        
        this.StartDate = startDate;
        
        if( startDate)
            this.StartTimeT = encodeDate(startDate);
        
        this.AdjustedClose = adjustedClose;
                    
        this.Profit = profit;
        this.Shares = shares;

    }
}