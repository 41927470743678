import { TopListDataResponseModel } from "../lib-export/everything";
import { CancelToken } from "../server-connection/talk-with-server";
import { PriceAlertRequestBuilder } from "./api-request-builders/price-alert-request.builder";
import { PriceAlertXMLToResponseModelMapper } from "./mappers/price-alert-xml-to-response-model.mapper";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";
import { CreatePriceAlertRequestModel } from "./models/price-alert/create-price-alert-request.model";
import { CreatePriceAlertResponseModel } from "./models/price-alert/create-price-alert-response.model";
import { PriceAlertResponseModel } from "./models/price-alert/price-alert-response.model";
import { PriceAlertManager } from "./models/price-alert/price-alert.manager";
import { QuoteFeedManager } from "./models/quote-feed/trade-quote.manager";

export class PriceAlertClient {
    private builder: PriceAlertRequestBuilder;
    private id = IdGenerator.NewId();
    private cancelToken: CancelToken | null = null;
    private priceAlertManager: PriceAlertManager;
    private tradeQuoteManager: QuoteFeedManager;

    private subscribePriceAlertDatas: Array<Function> = [];

    constructor() {
        this.builder = PriceAlertRequestBuilder.getInstance();
        this.priceAlertManager = PriceAlertManager.getInstance();
        this.tradeQuoteManager = QuoteFeedManager.getInstance();
        this.tradeQuoteManager.onSubscribeTopListData((symbol: string, toplistData: TopListDataResponseModel) => {
            this.priceAlertManager.updateLimitAlertsWithTopListData(symbol, toplistData);

            // const responseModel = new ClientResponseModel<Array<PriceAlertResponseModel>>();
            // responseModel.isSuccess = true;
            // responseModel.data = this.priceAlertManager.getAlerts();
            // this.notifyPriceAlertDataSubscribers(responseModel);
        });
    }

    public subscribe(setResponseOnDataCallback: Function): PriceAlertClient {
        this.subscribePriceAlertDatas.push(setResponseOnDataCallback);

        this.cancelToken = this.builder.priceAlertListenRequest(this.id, (responseXML: Element | null) => {
            const responseModel = new ClientResponseModel<Array<PriceAlertResponseModel>>();
            if (responseXML) {
                const data = PriceAlertXMLToResponseModelMapper.map(responseXML);
                TraceLoggingHelpers.log('PriceAlertClient subscribe', data)

                this.priceAlertManager.handleMessage(data);
                if (data) {
                    responseModel.isSuccess = true;
                } else {
                    responseModel.isSuccess = false;
                    responseModel.message = 'Model Empty';
                }
                
                responseModel.data = this.priceAlertManager.getAlerts();
                this.notifyPriceAlertDataSubscribers(responseModel);
            } else {
                responseModel.message = 'Response XML Empty';
                this.notifyPriceAlertDataSubscribers(responseModel);
            }
        });

        return this;
    }

    private notifyPriceAlertDataSubscribers(responseModel: ClientResponseModel<Array<PriceAlertResponseModel>>) {
        for (const subscriber of this.subscribePriceAlertDatas) {
            subscriber(responseModel);
        }
    }

    public unsubscribe() {
        if (this.cancelToken) {
            this.cancelToken();
            this.cancelToken = null;
        }

        this.builder.priceAlertStopRequest(this.id);
    }

    public async createPriceAlert(model: CreatePriceAlertRequestModel): Promise<ClientResponseModel<CreatePriceAlertResponseModel>> {
        model.created = new Date();
        model.updated = new Date();

        await this.builder.createPriceAlertRequest(model);
        
        const responseModel = new ClientResponseModel<CreatePriceAlertResponseModel>();
        responseModel.isSuccess = true;
        responseModel.data = new CreatePriceAlertResponseModel();
        responseModel.data.created = model.created;

        return responseModel;
    }

    public deletePriceAlert(id: string) {
        this.priceAlertManager.addRemovedId(id);
        this.builder.deletePriceAlertRequest(id);
    }
}