import { MetaData } from "../../../tipro-data/top-list-request";
import { TopListClient } from "../../top-list.client";
import { ClientResponseModel } from "../client-response/client-response.model";
import { TopListDataRequestModel } from "../top-list/top-list-data-request.model";
import { TopListDataResponseModel } from "../top-list/top-list-data-response.model";

interface QuoteFeedItem {
    client: TopListClient;
    toplistData?: TopListDataResponseModel;
}

// This class is taken from QuoteFeedManager.cs from TiPro
export class QuoteFeedManager {
    
    private tradeQuoteDict: Record<string, QuoteFeedItem> = {};

    private static instance: QuoteFeedManager | null = null;
    private readonly columnInternalCodes =
    [
        "D_Name", "D_Exch", "D_Symbol", "UpJan1P", "R60M", "U20DP", "RD", "MCap", "R5D", "ShortG", "RY", "SFloat", "Cash", "EPS", "Debt", "EarningD", "Revenue", "STH", "QRevG", "STP", "Price", "TV", "FCD", "FCP", "RV", "VWV"
    ];
    //command=ms_top_list_start&name=WlouTb1W&streaming=1&outside_market_hours=1&single_symbol=X&extra_column_formulas=_0=[D_Name]&_1=[D_Exch]&_2=[D_Symbol]&_3=[UpJan1P]&_4=[R60M]&_5=[U20DP]&_6=[RD]&_7=[MCap]&_8=[R5D]&_9=[ShortG]&_a=[RY]&_b=[SFloat]&_c=[Cash]&_d=[EPS]&_e=[Debt]&_f=[EarningD]&_g=[Revenue]&_h=[STH]&_i=[QRevG]&_j=[STP]&_k=[Price]&_l=[TV]&_m=[FCD]&_n=[FCP]&_o=[RV]&_p=[VWV]&message_id=27
    private subscribeTopListDatas: Array<(symbol: string, toplistData: TopListDataResponseModel) => void> = [];

    constructor() {}

    public static getInstance(): QuoteFeedManager {

        if (this.instance == null) {
            this.instance = new QuoteFeedManager();
        }

        return this.instance;
    }

    public onSubscribeTopListData(callback: (symbol: string, toplistData: TopListDataResponseModel) => void) {
        this.subscribeTopListDatas.push(callback);
    }

    private notifyTopListDataSubscribers(symbol: string, toplistData: TopListDataResponseModel) {
        for (const subscriber of this.subscribeTopListDatas) {
            subscriber(symbol, toplistData);
        }
    }

    public addQuoteFeed(symbol: string) {
        
        if (!symbol || this.tradeQuoteDict[symbol]) {
            return;
        }

        const request = new TopListDataRequestModel();
        request.outsideMarketHours = true;
        request.singleSymbol = symbol;
        request.streaming = true;

        for (const c of this.columnInternalCodes) {
            request.addExtraColumn(c);
        }

        this.tradeQuoteDict[symbol] = {
            client: new TopListClient(),
            toplistData: undefined
        }

        this.tradeQuoteDict[symbol].client.subscribe(request, (response: ClientResponseModel<TopListDataResponseModel>) => {
            if (response.isSuccess && response.data) {
                this.tradeQuoteDict[symbol].toplistData = response.data;
                this.notifyTopListDataSubscribers(symbol, response.data);
            }
        }, (metadataResponse: ClientResponseModel<MetaData>) => {

        });
    }
}