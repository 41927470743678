import { ClientResponseModel } from "../lib-export/everything";
import { haltedListenRequest } from "./api-request-builders/halted-request.builder";
import { HaltedRequestModel } from "./models/halted/halted-request.model";
import { HaltedResponseModel } from "./models/halted/halted-response.model";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";

export class HaltedClient {
    

    private haltedUnsubscribeWithCancelTokenCallback: any = null;

    constructor() {}

    public subscribe(requestModel: HaltedRequestModel, setResponseOnDataCallback: Function): HaltedClient {
        this.unsubscribe();

        if (requestModel.symbol) {
            requestModel.symbol = requestModel.symbol.toUpperCase();
        }

        this.haltedUnsubscribeWithCancelTokenCallback = haltedListenRequest(requestModel, (responseXML: Element | null) => {
            const model = new ClientResponseModel<HaltedResponseModel>();
            if (!responseXML) {
                setResponseOnDataCallback(model);
                return;
            }
    
            // <API halted_since="0" reason="" status="0" symbol="" type="halt_status"/>
            const responseModel = new HaltedResponseModel();
            const node = responseXML;
            responseModel.status = parseInt(node.getAttribute('status') ?? '');
            responseModel.reason = node.getAttribute('reason') ?? '';
            responseModel.showNotification = responseModel.status == 1;

            model.isSuccess = true;
            model.data = responseModel;
            
            setResponseOnDataCallback(model);
        });

        return this;
    }

    public unsubscribe() {
        TraceLoggingHelpers.log('unsubscribe ', this.haltedUnsubscribeWithCancelTokenCallback)
        if (this.haltedUnsubscribeWithCancelTokenCallback) {
            this.haltedUnsubscribeWithCancelTokenCallback();
            this.haltedUnsubscribeWithCancelTokenCallback = null;
        }
    }
}