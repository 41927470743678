import { csvStringToArray, getAttribute, makePromise } from "phil-lib/misc";
import { parseXml2, testXml2 } from "../../server-connection/polyfill-DomParser";
import { COMMAND, CancelToken, ResponseFromServer, createMessageToServer, responseToString } from "../../server-connection/talk-with-server";
import { Connection, decodeServerTime, ServerCommand } from "../connection.client";
import { ProfitBackfillRequestModel } from "../models/profit-backfill/profit-backfill-request.model";
import { Grid, SingleGridResponse } from "../../javascript-api/single-grid-request";
import { TraceLoggingHelpers } from "../models/helpers/trace-logging-helpers";
import { DateHelpers } from "../models/helpers/date-helpers";
import {ConnectionMaster} from "../../server-connection/connection-master"

export class ProfitBackfillRequestBuilder {

    private static instance: ProfitBackfillRequestBuilder;
    public static getInstance(): ProfitBackfillRequestBuilder {
        if (!this.instance) {
            this.instance = new ProfitBackfillRequestBuilder();
        }

        return this.instance;
    }

    private _connection: ConnectionMaster | undefined;

    public setConnection(connection:ConnectionMaster){
        this._connection = connection;
    }

    private sendWithStreamingResponse(serverCommand: ServerCommand, callback: (response: string) => void): CancelToken
    {

        //ProfitBackfillRequestBuilder is used both inside and outside the Shared Worker
        //When it runs outside it's connection is supplied by services/connection.client.ts
        //When it runs inside it uses the Connection Master Outbox
        //The caller of ProfitBackfillRequestBuilder must pass in the Connection Master reference using setConnection when 
        //using this class from the Shared Worker, otherwise it defaults to services/connection.client.ts which runs outside the
        //shared worker       

        if( this._connection){

            const messageToServer = createMessageToServer(serverCommand);
    
            const cancelRequest = this._connection.outbox.sendWithStreamingResponse(messageToServer, (response: ResponseFromServer) => {

                    const responseAsString = responseToString(response);
                    callback(responseAsString ?? "");
                }
            );
    
            return cancelRequest;
        }
        else{

            const cancelRequest = Connection.getInstance().sendWithStreamingResponse(serverCommand, callback);
            return cancelRequest; 

        }   
    }

    public async profitBackfillListenRequest(requestModel: ProfitBackfillRequestModel): Promise<Map<string, SingleGridResponse>> {
        const nowInMarketTimeZone = DateHelpers.getNowInMarketTimeZone().toJSDate();
        const marketOpenInMarketTimezone = DateHelpers.getMarketOpenTime().toJSDate();
        const preMarketOpenInMarketTimeZone = DateHelpers.getPreMarketOpenTime().toJSDate();

        let minutesSinceOpen = DateHelpers.getMinutesDifferentNoAbs(nowInMarketTimeZone,marketOpenInMarketTimezone);
        minutesSinceOpen = Math.min(Math.round(minutesSinceOpen), 630); // 390);
        if (minutesSinceOpen <= 0){
            minutesSinceOpen = 330; // 5.5 hours.
        }
        const symbolsUpperCase = requestModel.symbols.toUpperCase();
        const preMarketMinutes = DateHelpers.getPreMarketMinutes();
        const postMarketMinutes = DateHelpers.getPostMarketMinutes();
        const prototype = "intraday {{START {{current(\"start\")}}} {CLOSE {{}}} {adjCLOSE {{if_empty(ref(0, \"CLOSE\"), ref(1, \"adjCLOSE\"))}}}} {row_count " + minutesSinceOpen + " pack 0 minutes_per_candle 1 start_offset " + preMarketMinutes + " end_offset " + postMarketMinutes + "}";
        const messageToServer = [
            [COMMAND, "candles_command"],
            ["subcommand", "make_and_show"],
            ["prototype", prototype],
            ["symbols", symbolsUpperCase],
            ["show_errors", "1"]
        ];

        const symbolsArray = symbolsUpperCase.split(' ');
        const symbols: Set<string> = new Set(symbolsArray);
        const result = new Map<string, SingleGridResponse>();
        const done = makePromise();
        const cancelRequest = this.sendWithStreamingResponse(messageToServer as ServerCommand, (response: string) => {
            if (response === undefined) {
                TraceLoggingHelpers.log("Retrying because of server disconnect.");
                // TODO are we really RE-trying?  Maybe we succeeded 5 minutes ago?
                done.resolve();
            } else {
                const xml = parseXml2(response);
                if (!xml) {
                    done.resolve();
                } else {
                    const responseType = getAttribute("type", xml);
                    switch (responseType) {
                        case "result": {
                            const symbol = getAttribute("symbol", xml);
                            if (symbol === undefined || !symbols.has(symbol)) {
                                TraceLoggingHelpers.log("problem with symbol", symbol);
                            } else {
                                const body = xml.textContent ?? "";
                                const grid = csvStringToArray(body);

                                //Toss Bars Before the Pre Market 
                                if( minutesSinceOpen === 330){

                                    let i = 1;
                                    while ( i  < grid.length){

                                        let barDate = decodeServerTime(grid[i][2]);
                                        if( barDate && (DateHelpers.toMarketTimeZone(barDate).toJSDate() < preMarketOpenInMarketTimeZone))
                                            grid.splice(i, 1);
                                        else
                                            i++;
                                    }
                                }

                                result.set(symbol, { /*debug: body,*/ grid });
                                symbols.delete(symbol);
                                if (symbols.size == 0) {
                                    // Success!
                                    done.resolve();
                                    cancelRequest();
                                }
                                TraceLoggingHelpers.log(`Found ${symbol}, ${symbols.size} to go.`);
                            }
                            break;
                        }
                        case "done": {
                            TraceLoggingHelpers.log("got 'done' before getting all stock data");
                            done.resolve();
                            break;
                        }
                        default: {
                            // Default is to do nothing.  Sometimes the server will send out
                            // debug messages or similar.
                            TraceLoggingHelpers.log("responseType", responseType);
                            break;
                        }
                    }
                }
            }
        });

        await done.promise;
        cancelRequest();

        return result;
    }
}