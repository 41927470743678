/* This is the new way to access live top list data.  You can use this to fill a
 * traditional top list window.  You can also request specific data about a specific
 * stock.
 *
 * If you are looking for historical data, that will have to come from a different
 * class talking to a different server with a different API.  Look for TopListManager.
 * That is an older standard, and newer APIs look like TopListRequest in this file.
 *
 * This file talks with
 * cpp_alert_server/source/fast_alert_search/TopListMicroService.C on the server.
 *
 * This file was based heavily on
 * https://github.com/TI-Pro/TIPro/blob/development/TIProData/TopListRequest.cs
 */

import {
  followPath,
  getAttribute,
  makePromise,
  parseXml,
  testXml,  
} from "phil-lib/misc";

import {COMMAND, CancelToken, Connection, ServerCommand, decodeServerTime } from "../services/connection.client";
import { XmlAttributeToJsonMapper } from "../services/mappers/xml-attribute-to-json.mapper";
import { IdGenerator } from "../services/models/helpers/id-generator";
import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";



export class TopListRequest {
  /**
   * Use this to create a unique name for each request.  Names are required
   * so we can cancel the requests.  The server is designed to require a
   * unique name for each one, even if it's a one time request or for some
   * other reason you don't plan to ever cancel the request.  It seemed
   * simpler just to always require this.
   */
  
  /*
  Note: The original pure-javascript-api static counter with ++ approach may not be guarenteed unique 
  in a race condition where two toplist requests come in at the exact same time. 
  Trying a shortId string first, but may use a 36 character UUID. 
  TODO: Rename counter to topListId and just have it on the TopListRequest, but don't want to make that change just yet since it changes the property scope
  */
  //static #counter = 0n;
  counter: string;

  /**
   * A standard top list collaborate string.  Do not include the "http" part.
   * This is optional.  If you want to run a traditional top list window, set
   * this as always.  You can use some of the other properties to customize
   * this.  Or you can leave this as null, and only use the other properties
   * to describe your request.
   */
  collaborate?: string;

  /**
   * Do you want meta data?  If you're not going to use it, say no.
   *
   * Meta data only applies to the collaborate string.  If you don't provide one,
   * it really doesn't matter what you say here, there will be no meta data.
   */
  skipMetaData?: boolean;

  /**
   * This value is ignored if you set the collaborate string.  We always need
   * the database to interpret a collaborate string.  If this is false you'll
   * lose certain features, like the ability to use a user's custom formulas.
   * If you set this to true the initialization might take longer.
   */
  useDatabase?: boolean;

  /**
   * When is the last time we saw this data?
   *
   * If this is a new request leave this field as NULL, to say we've never
   * received any data.  This means two things to the server.
   * 1) We want the first request without any delays.
   * 2) If there are multiple requests that are ready for data now, a
   *    higher priority is given to requests that have never received
   *    data compared to requests that just have old data.
   *
   * Keep track of the time each time you receive data from the server.
   * If you have to automatically resend the request, use this field to
   * provide the time we received data.
   *
   * HasBeenSeen was used at one time for the same purpose.  It was a Boolean
   * so it wasn't precise enough.  Setting HasBeenSeen to false is like
   * setting LastUpdate to NULL.  Setting HasBeenSeen to true is like setting
   * LastUpdate to 0 or to the current time, depending which version of the
   * server you are attached to.  Neither 0 nor the current time was a great
   * answer, so now the server expects the client to give it more data.
   *
   * Warning:  The default value will request the highest priority.  If
   * a lot of requests keep the default value just because they were lazy,
   * there would be too many high priority requests.  The priority system
   * only works if some jobs have a lower priority.
   */
  #lastUpdate?: Date; // TODO this should be private and managed by this class.

  /**
   * `true` means the server sends responses until we explicitly cancel.
   * `false` means the server sends exactly one response.
   */
  streaming = false;

  /**
   * This only applies if you send a collaborate string.  If this is true we
   * save the collaborate string to the Most Recently Used list.  This only
   * looks at the collaborate string.  If you use other properties to customize
   * the request, those do not show up in the MRU list.
   */
  saveToMru?: boolean;

  /**
   * Do we want the columns from the collaborate string?
   */
  collaborateColumns?: boolean;

  /**
   * Set this to true if you want to see the most recent data regardless
   * of the time.  Set this to false if you want to see the most recent
   * data during market hours, but you want to freeze the data at the
   * close.  If you don't specify this value here we look at the value
   * in the collaborate string.
   */
  outsideMarketHours?: boolean;

  /**
   * How many results do you want to see?  By default this value is copied
   * from the the collaborate string.
   */
  resultCount?: number;

  /**
   * If you set this field the request will only return data for for this
   * symbol.  Otherwise it will look at all stocks.  This has the same
   * effect as setting a single symbol in the collaborate string.  You
   * shouldn't set this value if the collaborate string includes a single
   * symbol or a symbol list.  (That's different from ResultCount or
   * OutsideMarketHours where a value specified directly in this object
   * will completely override a value set in the collaborate string.)
   */
  singleSymbol?: string;

  /**
   * The format for this field is more or less the same as what you put
   * in the formula editor.  The default value is what is specified in
   * the collaborate string.
   *
   * This always returns the smallest value first.  In the top list config
   * window the user had a sort field and a choice of smallest or largest
   * first.  If you use this and you want the largest value first, try adding
   * a minus sign to your formula.  Under the hood that's how the traditional
   * top list window works.
   */
  sortFormula?: string;

  /**
   * The format for this field is more or less the same as what you put
   * in the formula editor.  The default value is what is specified in
   * the collaborate string.  If neither is specified this defaults to
   * "1" i.e. true.
   */
  whereFormula?: string;

  /**
   * These are data points that you want to see for each row.  These are
   * in addition to any that come from the collaborate string.  Each key
   * is the name of the field.  That same name is used in the response.
   * Each value a formula.  The format of the formula is more or less
   * the same as what you put in the formula editor.
   */
  readonly extraColumnFormulas = new Set<string>();

  private lifeCycleManagerHeartBeatBeatStatusTimer: any;
  private responsePassedThresholdTimer: any;
  private slowResponseMillisecondsThreshold = 15000;

  public constructor() { 
    //Note: The original pure-javascript-api static counter with ++ approach may not be guarenteed unique in a race condition where two toplist requests come in at the exact same time. Trying a shortId string first, but may use a 36 character UUID. 
    //TODO: Rename counter to topListId and just have it on the TopListRequest, but don't want to make that change just yet since it changes the property scope
    this.counter = IdGenerator.NewId();
  }
  
  private setLifeCycleManagerHeartBeatTimer() {
    clearTimeout(this.lifeCycleManagerHeartBeatBeatStatusTimer);
    this.lifeCycleManagerHeartBeatBeatStatusTimer = setTimeout(() => {
      this.setLifeCycleManagerHeartBeatTimer();
    }, this.slowResponseMillisecondsThreshold);
  }

  private setResponsePassedThresholdTimer() {
    clearInterval(this.responsePassedThresholdTimer);
    this.responsePassedThresholdTimer = setInterval(() => {
      TraceLoggingHelpers.log(`TopListRequest - waiting for response passed slowResponseMillisecondsThreshold: ${this.slowResponseMillisecondsThreshold}, Request Model: `, this);

      this.setLifeCycleManagerHeartBeatTimer();
    }, this.slowResponseMillisecondsThreshold);
  }

  /**
   * Add a column to ExtraColumnFormulas.
   * If this is a duplicate request, silently ignore it.
   * @param formula The data we want for this key.
   * The format is basically the same as the formula editor formulas.
   */
  addExtraColumn(formula: string): void {
    // Do we even need this function?  The C# version was much more complicated.
    // TODO consider removing this.
    this.extraColumnFormulas.add(formula);
  }

  /**
   * These are called "magic columns" in some places because the older APIs used to
   * request these automatically.  We add these field for most requests
   * that might show up in a row in a table.  When the user right clicks or double clicks
   * he might use some shared code, like the "send to" feature.  That feature will always
   * expect to see the exchange and the symbol, even if the user did not want to see
   * those fields.
   */
  addMagicColumns(): void {
    WellKnownColumns.ALL_FORMULAS.forEach((formula) =>
      this.addExtraColumn(formula)
    );
  }

  private serverLogicFailure() {
    // This is a bit of a sledgehammer.  This is a good thing to do when you
    // see a nonsense reply from the server.  The assumption is that this
    // condition is rare and that it probably represents some serious
    // communications error.  Or maybe there is one bad server in the pool, and
    // next time I'll get hooked up to a better one.  In any case, force a
    // disconnect from the server and let the normal reconnect logic do its
    // thing.
    //
    // My only concern is that there is some problem that doesn't go away when
    // we reconnect.  What if there is something wrong with the top list code,
    // and the server keeps sending the same thing and the client keeps rejecting
    // it?  Then we might make all of the main program unusable when only one
    // feature is flawed.
    //
    // I considered canceling and resending just this one request.  That seemed like it
    // might cause more problems than it solves.  It makes the code much more
    // complicated and hard to test.
    //
    // TODO I suppose a delay with exponential back off would help.  The first
    // time we see this we pause one second before resetting.  If we have to
    // retry a second time, we pause 2 seconds.  Doubling each time.  And be
    // sure to stop retrying if and when the main program cancels the request.
    // This seems like a nice compromise.
    Connection.getInstance().softReset();
  }

  /**
   * Send a request to the server and listen for responses.
   *
   * You should always clone the TopListRequest before calling this.
   * This includes automatic retries.  And that will use the same
   * TopListRequest to make every call.  So different requests
   * cannot share a TopListRequest object.
   * @param listener All responses come here.
   * @returns A cancel token to call when you are done.
   */
  private send(listener: Listener): CancelToken {
    this.setLifeCycleManagerHeartBeatTimer();

    //Note: The original pure-javascript-api static counter with ++ approach may not be guarenteed unique in a race condition where two toplist requests come in at the exact same time. Trying a shortId string first, but may use a 36 character UUID. 
    //TODO: Rename counter to topListId and just have it on the TopListRequest, but don't want to make that change just yet since it changes the property scope
    // TopListRequest.#counter++;
    // const topListId = TopListRequest.#counter.toString(36);
    let topListId = this.counter;
    
    
    
    let encodedColumns = "";
    let columnDecoder = new Map<string, string>();
    let nextColumnId = 0;
    /**
     * If this is false then the server will only send us data from requested in `extraColumnFormulas`.
     * If this is true then we might get some fields that were requested by the collaborate string.
     */
    const useColumnsFromCollaborate =
      this.collaborate !== undefined && this.collaborateColumns !== false;
    this.extraColumnFormulas.forEach((formula) => {
      let columnId: string | undefined;
      if (useColumnsFromCollaborate) {
        columnId = columnFormulaToWireName(formula);
      }
      if (columnId === undefined) {
        columnId = "_" + nextColumnId.toString(36);
        nextColumnId++;
      }
      if (encodedColumns != "") {
        encodedColumns += "&";
      }
      encodedColumns += columnId;
      encodedColumns += "=";
      encodedColumns += encodeURIComponent(formula);
      columnDecoder.set(columnId, formula);
    });
    let internalCancelToken: CancelToken | undefined;
    let finished = false;
    const sendRequestToServer = () => {
      let requestedTime = new Date();
      const messageToServer = [
        [COMMAND, "ms_top_list_start"],
        ["name", topListId],
        ["collaborate", this.collaborate],
        ["skip_metadata", this.skipMetaData],
        ["use_database", this.useDatabase],
        ["last_update", this.#lastUpdate],
        ["streaming", this.streaming],
        ["save_to_mru", this.saveToMru],
        ["collaborate_columns", this.collaborateColumns],
        ["outside_market_hours", this.outsideMarketHours],
        ["result_count", this.resultCount],
        ["single_symbol", this.singleSymbol],
        ["sort_formula", this.sortFormula],
        ["where_formula", this.whereFormula],
        // "|| undefined" is a neat trick.
        // send() will automatically skip any fields where the value is undefined.
        // Usually send() will send all strings and numbers.
        // Adding "|| undefined" here is a quick way to say "skip this one field if
        // the value is 0 or the empty string."
        ["extra_column_formulas", encodedColumns || undefined],
      ] as ServerCommand;

      this.setResponsePassedThresholdTimer();

      const callback = (response: string) => {
        if (!finished) {
          if (response === undefined) {
            // Communications error.  Auto retry!
            // TODO: Remove counter all together and just have topListId, but don't want to test that change more first since it's changes scope
            // Note: also creating a new topListId, since we have seen that sending the same command message name twice results in the second message not returning
            const originalTopListId = topListId;
            this.counter = IdGenerator.NewId();
            topListId = this.counter;
            TraceLoggingHelpers.log(`Retrying request (possibly from message -1, ServerClosed), setting new topListId. originalTopListId: ${originalTopListId}, new topListId: ${topListId}`);
            sendRequestToServer();
          } else {
            const xml = parseXml(response);
            const topListElement = followPath(xml, "TOPLIST");
            if (!topListElement) {
              this.serverLogicFailure();
              console.error(
                testXml(response),
                response,
                "failed to parse -> softReset()"
              );
            } else {

              const now = new Date();
              const slowResponseThresholdInMs = now.valueOf() - requestedTime.valueOf();
              if (slowResponseThresholdInMs > this.slowResponseMillisecondsThreshold) {
                TraceLoggingHelpers.log(`TopListRequest - slow response for topListId ${topListId}, passed slowResponseThreshold: ${this.slowResponseMillisecondsThreshold}, duratrion: ${slowResponseThresholdInMs} ms. Response: ${response} Request:`, this);
              }

              requestedTime = now;

              this.setResponsePassedThresholdTimer();

              const messageType = getAttribute("TYPE", topListElement);
              switch (messageType) {
                case "data": {
                  const data: Data = {
                    rows: decodeRows(
                      topListElement,
                      columnDecoder,
                      useColumnsFromCollaborate
                    ),
                    start: decodeServerTime(
                      getAttribute("START_TIME", topListElement)
                    ),
                    end: decodeServerTime(
                      getAttribute("END_TIME", topListElement)
                    ),
                  };
                  try {
                    listener.onData(data);
                  } catch (reason) {
                    console.error(reason);
                  }
                  break;
                }
                case "info": {
                  try {
                    let metaDataJson: any = null;


                    if (xml) {
                      metaDataJson = XmlAttributeToJsonMapper.map(xml);
                    }

                    const metaData: MetaData = { metaDataXml: xml?.outerHTML, metaDataJson: metaDataJson };
                    listener.onMetaData(metaData);
                  } catch (reason) {
                    console.error(reason);
                  }
                  break;
                }
              }
            }
          }
        }
      };
      internalCancelToken =
      Connection.getInstance().sendWithStreamingResponse(
          messageToServer,
          callback
        );
    };
    sendRequestToServer();

    return () => {
      if (!finished) {
        // Disable retries
        finished = true;
        // Stop listening for replies.
        internalCancelToken?.();
        if (this.streaming) {
          clearInterval(this.responsePassedThresholdTimer);
          // TODO Tell the server to stop sending data‼
          const messageToServer = [
            [COMMAND, "ms_top_list_stop"],
            ["name", topListId],
          ] as ServerCommand;
          Connection.getInstance().sendWithNoResponse(messageToServer);
        }
      }
    };
  }

  sendStreaming(listener: Listener): CancelToken {
    const toSend = this.clone();
    toSend.streaming = true;
    return toSend.send(listener);
  }

  sendOnce(): Promise<CombinedData> {
    const promise = makePromise<CombinedData>();
    let metaData: MetaData | undefined;
    const cancelToken = this.clone().send({
      onData(data: CombinedData) {
        cancelToken();
        data.metaData = metaData;
        promise.resolve(data);
      },
      onMetaData(newMetaData) {
        metaData = newMetaData;
      },
    });
    return promise.promise;
  }

  clone(): TopListRequest {
    const result = new TopListRequest();
    result.collaborate = this.collaborate;
    result.collaborateColumns = this.collaborateColumns;
    this.extraColumnFormulas.forEach((formula) =>
      result.extraColumnFormulas.add(formula)
    );
    result.#lastUpdate = this.#lastUpdate;
    result.outsideMarketHours = this.outsideMarketHours;
    result.resultCount = this.resultCount;
    result.saveToMru = this.saveToMru;
    result.streaming = this.streaming;
    result.singleSymbol = this.singleSymbol;
    result.skipMetaData = this.skipMetaData;
    result.sortFormula = this.sortFormula;
    result.useDatabase = this.useDatabase;
    result.whereFormula = this.whereFormula;
    return result;
  }
}

function decodeRow(
  element: Element,
  fieldNames: ReadonlyMap<string, string>,
  useColumnsFromCollaborate: boolean
): RowData {
  const result = new Map<string, string>();
  const attributes = element.attributes;
  const length = attributes.length;
  for (let i = 0; i < length; i++) {
    const attribute = attributes[i];
    let fieldName = fieldNames.get(attribute.name);
    if (useColumnsFromCollaborate && fieldName === undefined) {
      fieldName = wireNameToColumnFormula(attribute.name);
    }
    if (fieldName !== undefined) {
      result.set(fieldName, attribute.value);
    }
  }
  return result;
}

export function decodeRows(
  parent: Element,
  fieldNames: ReadonlyMap<string, string>,
  useColumnsFromCollaborate: boolean
) {
  return Array.from(parent.children).map((child) =>
    decodeRow(child, fieldNames, useColumnsFromCollaborate)
  );
}

export type RowData = Map<string, string>;

export type MetaData = {
  metaDataXml?: string;
  metaDataJson?: any;
};

/* This is a sample of what the metadata looks like:
<TOPLIST 
  SHORT_FORM="form=1&amp;show0=D_Symbol&amp;show1=Price&amp;show2=FCP&amp;show3=PUp2&amp;show4=Vol5&amp;show5=R20D&amp;show6=RV&amp;show7=TRangeP&amp;show8=SFloat&amp;col_ver=1&amp;MinFCP=0.1&amp;MinPrice=0.2&amp;MinR5D=77&amp;MinRV=1&amp;MinRY=15&amp;MinSFloat=20&amp;MinTRangeP=110&amp;sort=MaxTRangeP&amp;X_NYSE=on&amp;X_ARCA=on&amp;X_AMEX=on&amp;XN=on&amp;WN=Short+Squeeze&amp;count=25" 
  TYPE="info" 
  WINDOW_NAME="Short Squeeze">
  <COLUMNS>
    <c_D_Symbol CODE="D_Symbol" DESCRIPTION="Symbol" FORMAT="" TEXT_FIELD="1" TEXT_HEADER="1" de_DESCRIPTION="Kürzel"/>
    <c_Price CODE="Price" DESCRIPTION="Price" FORMAT="p" UNITS="$" de_DESCRIPTION="Preis" de_UNITS="Dollars"/>
    <c_FCP CODE="FCP" DESCRIPTION="Change from the Close" FORMAT="1" UNITS="%" de_DESCRIPTION="Über dem Schlusskurs" de_UNITS="in Prozent"/>
    <c_PUp2 CODE="PUp2" DESCRIPTION="Change 2 Minute" FORMAT="1" UNITS="%"/>
    <c_Vol5 CODE="Vol5" DESCRIPTION="Volume 5 Minute" FORMAT="1" UNITS="%" de_DESCRIPTION="Volumen 5" de_UNITS="in Prozent"/>
    <c_R20D CODE="R20D" DESCRIPTION="Position in 20 Day Range" FORMAT="1" GRAPHICS="%R" UNITS="%" de_DESCRIPTION="Position in 20 Tages Bandbreite" de_UNITS="in Prozent"/>
    <c_RV CODE="RV" DESCRIPTION="Relative Volume" FORMAT="2" UNITS="Ratio" de_DESCRIPTION="Aktuelles Volumen" de_UNITS="Verhältnis"/>
    <c_TRangeP CODE="TRangeP" DESCRIPTION="Today's Range" FORMAT="1" UNITS="%" de_DESCRIPTION="today's range" de_UNITS="in Prozent"/>
    <c_SFloat CODE="SFloat" DESCRIPTION="Short Float" FORMAT="2" UNITS="%"/>
  </COLUMNS>
  <SORT_BY FIELD="TRangeP"/>
</TOPLIST>
This corresponds to the topListElement variable in the code, not to the entire XML document.
*/

/* And here is the corresponding data:
<TOPLIST END_TIME="1658433899" START_TIME="1658433600" TYPE="data" WINDOW="2">
  <NODE c_D_Symbol="OSH" c_FCP="5.2256532" c_PUp2="-0.15048909" c_Price="26.58" c_R20D="111.87699" c_RV="5.859453" c_SFloat="20.6342" c_TRangeP="195.3617" c_Vol5="2505.6387" />
  <NODE c_D_Symbol="NKLA" c_FCP="7.3005093" c_PUp2="-0.63291139" c_Price="6.32" c_R20D="119.43574" c_RV="1.2134238" c_SFloat="27.59" c_TRangeP="165.39861" c_Vol5="924.71281" />
  <NODE c_D_Symbol="BLNK" c_FCP="9.9459459" c_PUp2="-2.1632252" c_Price="20.34" c_R20D="129.6837" c_RV="1.8127641" c_SFloat="29.74" c_TRangeP="163.15409" c_Vol5="491.83111" />
  <NODE c_D_Symbol="SENS" c_FCP="7.8740157" c_PUp2="0" c_Price="1.37" c_R20D="93.181818" c_RV="1.2901845" c_SFloat="21.7346" c_TRangeP="150.52614" c_Vol5="515.44552" />
</TOPLIST>
When I removed the Symbol column from the config string, I no longer got the symbol in the output.
"Magic Columns" were on by default in old versions of the API, but now they only come on request.
 */

export type Data = {
  rows: RowData[];
  start?: Date;
  end?: Date;
};

export type CombinedData = Data & { metaData?: MetaData };

export type Listener = {
  onData(data: Data): void;
  onMetaData(metaData: MetaData): void;
};

/**
 *
 * These are called "magic columns" in some places.  We request these for most requests
 * that might show up in a row in a table.  When the user right clicks or double clicks
 * he might use some shared code, like the "send to" feature.  That feature will always
 * expect to see the exchange and the symbol, even if the user did not want to see
 * those fields.
 *
 * These are not specific to the top list.  Presumably we'll use these in the alert
 * window at some point.
 */
export class WellKnownColumns {
  private constructor() { }
  static readonly SYMBOL = "[D_Symbol]";
  static readonly EXCHANGE = "[D_Exch]";
  static readonly FOUR_DIGITS = "price<1.0";
  static readonly ALL_FORMULAS: readonly string[] = [
    this.SYMBOL,
    this.EXCHANGE,
    this.FOUR_DIGITS,
  ];
}

// Do we need this?
// A lot of the older code knew a lot about specific filters.  Like "Price".
// The newer code focuses on formulas, like "[Price]".
// If you ask for the Price column via the config window, that will translate
// to "Price" in the collaborate string.
function filterToFormula(internalCode: string) {
  return "[" + internalCode + "]";
}

// TODO use columnFormulaToWireName() and wireNameToColumnFormula()
// This is required to read data requested by the collaborate string.
// And this will make it more efficient if you request the same data via a formula and a collaborate string.
// E.g. if the user chose to display the symbol column using the config window,
// and the program asks for the symbol column using addMagicColumns().

/**
 * If we request a column using a collaborate string, the server will send the results
 * back using a wire name like "c_Price" or "c_D_Symbol".  If we request a column
 * using TopListRequest.extraColumnFormulas, we can tell the server what name to
 * use on the wire.  If we request the same column both ways, it would be nice if
 * both used the same wire name.  Then the server will only send us one copy.
 *
 * This function looks at a formula and decides if that same formula could come from
 * a collaborate string.  If so, this returns the standard value, like "c_Price".
 * Otherwise this returns undefined and the caller can find some other way to pick
 * the wire name for this formula.
 * @param formula The formula we want the server to compute.
 * @returns `undefined` if the formula could __not__ overlap with a collaborate string.
 * Otherwise, a string which will __not__ be falsy.  That string will be the "wire
 * name" for this field.  I.e. that string will be the name of the xml attribute
 * holding this value.
 */
export function columnFormulaToWireName(formula: string): string | undefined {
  const match = /^\[([_0-9a-zA-Z]+)\]$/.exec(formula);
  if (!match) {
    return undefined;
  } else {
    return "c_" + match[1];
  }
}

/**
 * wireNameToColumnFormula() and columnFormulaToWireName() are inverses.
 *
 * This looks at the name of a field in a message from the server.  Sometimes the
 * client know all of the field names in advance because the client requested
 * those exact field names.  But if a field was requested in the collaborate
 * string, the client will not be expecting it.  This function looks to see
 * if a field name was automatically generated by the server because of a
 * collaborate string.  If not, this returns `undefined`.  If this was from the
 * collaborate string, return a formula that would have given the same
 * result.
 *
 * Assume you want the symbol associated with a row.  You would always look
 * for the key "[D_Symbol]" in the RowData object.  It doesn't matter if you
 * requested this data via `extraColumnFormulas`, `collaborate` or both.
 * If we see "c_D_Symbol" as the name of an attribute in a message from the
 * server, this functions will translate that to "[D_Symbol]".
 * @param wireName The name of an attribute in a message from the server.
 * @returns `undefined` if wireName does not refer to a simple column.
 * Otherwise returns a formula that means the same thing.  The formula will
 * be a string which will not be falsy.
 */
export function wireNameToColumnFormula(wireName: string): string | undefined {
  const match = /^c_([_0-9a-zA-Z]+)$/.exec(wireName);
  if (!match) {
    return undefined;
  } else {
    return "[" + match[1] + "]";
  }
}
