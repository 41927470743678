import { SymbolListResponseModel } from "../models/symbol-list/symbol-list-response.model";

export class SymbolListXMLToResponseModelMapper {
    public static map(rawXML: Element): Array<SymbolListResponseModel> {
        const result: Array<SymbolListResponseModel> = [];
        const symbolListNodes = rawXML.getElementsByTagName('SYMBOL_LISTS');
        
        if (symbolListNodes?.length > 0) {
            for (let i = 0; i < symbolListNodes[0].childNodes.length; i++) {
                const symbolListNode = symbolListNodes[0].children[i];
                const internalCode = symbolListNode.nodeName;
                if (!internalCode.includes('SL_0_') && !internalCode.includes('SL_1_')) {
                    const listDescription = symbolListNode.getAttribute('NAME');
                    const listNames = listDescription?.split('|');
                    if (listNames?.length) {
                        const listName = listNames[listNames.length - 1].trim();
                        const model = new SymbolListResponseModel({
                            internalCode: internalCode,
                            listDescription: listDescription ?? '',
                            listName: listName
                        });

                        result.push(model);
                    }
                }
            }
        }

        return result;
    }
}