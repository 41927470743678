import { makePromise } from "phil-lib/misc";
import { LifecycleManager, Outbox } from "./lifecycle-manager";
import {
  CancelToken,
  MessageToServer,
  ResponseFromServer,
} from "./talk-with-server";

/**
 * Something that we want to deliver.
 * We might have to deffer this call until we have a valid Outbox.
 */
type Deferrable = (outbox: Outbox) => void;

/**
 * Everything sent through send manager should get sent the same order it was submitted.
 * Internally we have to be careful about reentrant calls and the like.
 * Externally, you don't have to think:  Call send manager **any time** it is convenient, and the
 * requests will be sent it order.
 *
 * Messages generated from within the ConnectionMaster can insert themselves at the beginning of a new
 * connection.  The first message on a new connection should be login, regardless of anything.
 * The SendManager's queue should probably be the last thing that we send.
 * Then new requests are sent to the server as soon as they are submitted.
 */
export class SendManager implements Outbox {
  constructor(private readonly lifecycleManager: LifecycleManager) {}
  
  public sendWithNoResponse(messageToServer: MessageToServer) {
    const outbox = this.lifecycleManager.outbox;
    outbox?.sendWithNoResponse(messageToServer);
  }

  /**
   *
   * @param messageToServer What to send.
   * @returns A `CancelToken` used to abort the request.  This has the same effect as a broken network connection.
   * And a promise.  This will resolve to an array of bytes on success, or undefined in case of any error.
   * This promise will never reject.
   */
  public sendWithSingleResponse(messageToServer: MessageToServer): {
    cancel: CancelToken;
    promise: Promise<ResponseFromServer>;
  } {
    /**
     * Use this to record if someone cancels a request while this
     * `SendManger` still has it queued up.
     *
     * In this case we cancel the request before it ever gets to
     * the server.
     */
    let canceled = false;
    /**
     * After we hand this request off to TalkWithServer (via the
     * `LifecycleManager`) we use to pass any cancel requests on
     * to the TalkWithServer.  If we get a response from the server
     * after the cancel, then just throw that response away, don't
     * deliver it.
     */
    let nextCancelToken: CancelToken | undefined;
    /**
     * Return this function to the caller.
     */
    function cancel() {
      canceled = true;
      nextCancelToken?.();
    }
    const promise = makePromise<ResponseFromServer>();
    /**
     * Forward the request.
     * @param outbox The code that will _send_ our request to
     * the server.  `Outbox` is just an interface and `outbox`
     * is almost certainly a `TalkWithServer`.
     */
    function doIt(outbox: Outbox) {
      if (!canceled) {
        const result = outbox.sendWithSingleResponse(messageToServer);
        nextCancelToken = result.cancel;
        result.promise.then((responseFromServer) =>
          promise.resolve(responseFromServer)
        );
      }
    }
    /**
     * Add the request to the queue.  It might run very soon
     * or it might wait for us to connect to the server.  In any
     * case, the queue is a fifo.
     */
    this.send(doIt);
    return { cancel, promise: promise.promise };
  }

  /**
   * Request streaming data from the server.
   * @param messageToServer What to send to the server.
   * @param callback Where to send the responses from the server.
   * @returns A `CancelToken`.  Use this if you don't want any more replies.
   *
   * This only affects the client communication library.  You might need a
   * separate operation to tell the server that we are done listening.
   * This tells the client to give up some memory that it no longer needs.
   * And if we get more responses from the server, this client code will
   * throw the messages away.
   */
  public sendWithStreamingResponse(
    messageToServer: MessageToServer,
    callback: (response: ResponseFromServer) => void
  ): CancelToken {
    /**
     * Use this to record if someone cancels a request while this
     * `SendManger` still has it queued up.
     *
     * In this case we cancel the request before it ever gets to
     * the server.
     */
    let canceled = false;
    /**
     * After we hand this request off to TalkWithServer (via the
     * `LifecycleManager`) we use to pass any cancel requests on
     * to the TalkWithServer.  If we get a response from the server
     * after the cancel, then just throw that response away, don't
     * deliver it.
     */
    let nextCancelToken: CancelToken | undefined;
    /**
     * Return this function to the caller.
     */
    function cancel() {
      canceled = true;
      nextCancelToken?.();
    }
    /**
     * Forward the request.
     * @param outbox The code that will _send_ our request to
     * the server.  `Outbox` is just an interface and `outbox`
     * is almost certainly a `TalkWithServer`.
     */
    function doIt(outbox: Outbox) {
      if (!canceled) {
        nextCancelToken = outbox.sendWithStreamingResponse(
          messageToServer,
          callback
        );
      }
    }
    /**
     * Add the request to the queue.  It might run very soon
     * or it might wait for us to connect to the server.  In any
     * case, the queue is a fifo.
     */
    this.send(doIt);
    return cancel;
  }

  /**
   * This is a FIFO queue of requests.
   * We might hold these until the network connection is ready.
   * This is especially useful when an old network connection goes down,
   * and we report that to the main program, and the main program starts
   * sending replacement messages while the communication library is
   * still finishing the old network connection.
   */
  #deferred: Deferrable[] | undefined;

  /**
   * Send this request to the server, possibly queuing it up
   * in `this.#deferred`, first.
   * @param toSend The message to send to the server.
   */
  private send(toSend: Deferrable) {
    if (this.#deferred) {
      // There is a line, wait at the end.
      this.#deferred.push(toSend);
    } else {
      const outbox = this.lifecycleManager.outbox;
      if (outbox) {
        // Try it now.
        toSend(outbox);
      } else {
        // There is no current connection.  Defer until we have
        // a connection.
        this.#deferred = [toSend];
      }
    }
  }

  /**
   * Call this immediately after this.lifecycleManager.outbox
   * becomes truthy.
   * @returns
   */
  connected() {
    const deferred = this.#deferred;
    if (!deferred) {
      // No work to do.
      return;
    }
    const lifecycleManager = this.lifecycleManager;
    while (true) {
      const outbox = lifecycleManager.outbox;
      if (!outbox) {
        // The connection is back down.  Keep the remaining items in
        // the queue.
        //
        // Interesting.  You might queue up a lot of items in a
        // particular order.  (Queue contains R1a, R2a, R3a, R4a.)
        // The first couple get sent to the server, but then the
        // network connection goes down will some items remain in
        // the queue.  (The queue now contains R3a, R4a.)
        // The first items (R1a and R2a) fail, and we report that, and
        // the main program responds by adding replacements (R1b and
        // R2b) to the queue.  (The queue now contains R3a, R4a, R1b,
        // R2b)  Notice that the messages are no longer in order.
        // If we reconnect with no other errors, the server will
        // receive request 3, then request 4, then (the second copy
        // of) request 1, then request 2.
        //
        // This is why the symbol list code only sends one message
        // at a time per symbol list.  (However, a message can be
        // big and complicated.  You only need multiple messages if
        // you want additional changes after sending your first
        // message.)
        return;
      }
      /**
       * The next item in the FIFO.
       */
      const nextRequest = deferred.shift();
      if (!nextRequest) {
        // No more backlog.  New requests can now bypass the queue
        // and go directly to the TalkWithServer.
        this.#deferred = undefined;
        return;
      }
      nextRequest(outbox);
    }
  }
}
