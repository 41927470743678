import { parseTimeT } from "phil-lib/misc";
import { HolidayCountry } from "../models/enums/holiday-country.enum";
import { HolidayModel } from "../models/holiday/holiday.model";

export class HolidayAlertsXMLToResponseModelMapper {
    public static map(rawXML: Element): Array<HolidayModel> {
        const result: Array<HolidayModel> = [];

        const type = rawXML.getAttribute("type");
        if (type == "holidays") {
            const holidayCount = parseInt(rawXML.getAttribute("COUNT") ?? '', 10);

            for (let i = 1; i <= holidayCount; i++) {
                const timet = parseFloat(rawXML.getAttribute("DAY" + i) ?? '');
                const country = rawXML.getAttribute("COUNTRY" + i) ?? '';
                const fullday = parseInt(rawXML.getAttribute("CLOSED" + i) ?? '', 10) == 1;
                const halfday = parseInt(rawXML.getAttribute("HALF_DAY" + i) ?? '', 10) == 1;
                const day = parseTimeT(timet);
                if (day) {
                    const holiday = new HolidayModel();
                    holiday.date = day;
                    holiday.country = country as HolidayCountry;
                    holiday.closed = fullday;
                    holiday.halfDay = halfday;

                    result.push(holiday);
                }
            }
        }

        return result;
    }
}