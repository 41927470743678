import { ChartIndicatorParameterModel } from "./chart-indicator-parameter.model";

export class ChartIndicatorResponeModel {
    public id = 0;
    public referenceName = '';
    public displayName = '';
    public abbreviatedName = '';
    public prefersOwnPane = false;
    public code = '';
    public category = '';
    public colorType = '';
    public indicatorType = '';
    public color = '';
    public upColor = '';
    public downColor = '';
    public topColor = '';
    public bottomColor = '';
    public parameters: Array<ChartIndicatorParameterModel> = [];
}