
import { ProfitBar } from "./profit-bar";

export class BrokerPosition {

    public Key: string = "";
    public InstanceId: string = "";
    public AccountId:string = "";
    public Symbol:string = "";
    public AverageCost:number | undefined = undefined;
    public Shares:number  = 0;
    public SharesBought:number | undefined = undefined;
    public SharesSold:number | undefined = undefined;
    public LastPrice:number | undefined = undefined;

    //New Instrument Fields 
    public iLast:number  = 0;
    public iChangeFromCloseDollar:number  = 0;
    public iChangeFromClosePercent:number = 0;
    public iLastClose:number| undefined = undefined;
    public iTradeSize:number| undefined = undefined;
    public iBidSize:number| undefined = undefined;
    public iBid:number| undefined = undefined;
    public iAskSize:number| undefined = undefined;
    public iAsk:number| undefined = undefined;
    public iVolume:number| undefined = undefined;
    public iReportingExchange:string| undefined = undefined;


    public UnrealizedProfitLoss:number | undefined = undefined;
    public RealizedProfitLoss:number | undefined = undefined;
    public MarketValue:number | undefined = undefined;
    
    public Created:Date | undefined = undefined;
    public Commissions:number = 0;

    public IsLong: boolean | undefined = undefined;

    //New Position Fields 
    public TotalProfit:number = 0;
    public TotalProfitPercent:number = 0;
    public TodayAveragePrice:number = 0;
    public TodayProfit:number = 0;
    public TodayProfitPercent:number = 0;
    public NetSharesTradedToday:number = 0;
    public NetMoneyTradedToday:number = 0;
    public PositionPlusMinus:number = 0;
    public PositionPlusMinusPercent:number = 0;
    public EntryMethod:string= "not-manual";


    

    public ProfitBars:Array<ProfitBar>;

    constructor() {
        this.ProfitBars = new Array<ProfitBar>();
    }

}