export class TopListDataRequestModel {
    public resultCount?: number;
    public sortFormula?: string;
    public collaborate?: string;
    public singleSymbol?: string;
    public skipMetaData?: boolean;
    public streaming?: boolean;
    public collaborateColumns?: boolean;
    public outsideMarketHours?: boolean;
    public extraColumns: Array<string> = [];
    public companyNameFilterList: Array<string> = [
        ' - American Depositary Shares',
        ' - American Depository Shares',
        ' - Common shares', ' - Class ',
        ' - Ordinary Shares',
        ' American Depositary Shares, each representing two Ordinary Shares'];
    
    public addExtraColumn(column: string) {
        if (column) {
            this.extraColumns.push(column);
        }
    }
    
    public removeConfigStringPropertyIfPropertyNameSet(modelPropertyName: string, configStringName: string) {
        const configStringProperty = `&${configStringName}=`;
        const modelAsAny = this as any;
        if (modelAsAny[modelPropertyName] && this.collaborate && this.collaborate.indexOf(configStringProperty) > -1) {
            // Split the string into an array based on '&'
            const parts = this.collaborate.split('&');

            // Filter out the part you want to remove
            const filteredParts = parts.filter(part => part.indexOf(configStringName) == -1);

            // Join the filtered parts back into a single string using '&'
            this.collaborate = filteredParts.join('&');
        }
    }
}