import { parseXml } from "phil-lib/misc";
import { StreamingAlertsRequestModel } from "../models/streaming-alerts/streaming-alerts-request.model";
import {COMMAND, CancelToken, Connection, ServerCommand} from "../../services/connection.client";

type StreamingAlertCallback = {
  id: string;
  callback: Function;
};

export class StreamingAlertRequestBuilder {

  private static instance: StreamingAlertRequestBuilder;
  public static getInstance(): StreamingAlertRequestBuilder {
    if (!this.instance) {
      this.instance = new StreamingAlertRequestBuilder();
    }

    return this.instance;
  }

  private callbackListeners: Array<StreamingAlertCallback> = [];

  public async streamingAlertsStartRequest(id: string, requestModel: StreamingAlertsRequestModel): Promise<Element | undefined> {

    const messageToServer = [
      [COMMAND, "ms_alert_start"],
      ["strategy_id", id],
      ["long_form", requestModel.config],
      ["save_to_mru", requestModel.saveToMru ? "1" : "0"]
    ] as ServerCommand;

    var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
  
    const xml = parseXml(response);

    return xml;
  }

  public streamingAlertsListenRequest(nextId: string | null, strategyId: string, callback: Function): CancelToken {
    
    
    const messageToServer = [
      [COMMAND, "ms_alert_listen"],
      ["next_id", nextId]
    ] as ServerCommand;

    this.callbackListeners.push({ id: strategyId, callback: callback });

    return Connection.getInstance().sendWithStreamingResponse(messageToServer, (response: string) => {
      const xml = parseXml(response);
      nextId = xml?.children.item(1)?.getAttribute('NEXT_ID') ?? null;

      const children = xml?.children?.item(0)?.children;
      if (children) {
        for (const item of Array.from(children)) {
          const id = item.getAttribute('ID');
          const callBackListener = this.callbackListeners.find((x: StreamingAlertCallback) => x.id == id);
          if (callBackListener) {
            callBackListener.callback(xml, nextId);
          }
        }
      }
    });
  }

  public streamingAlertsStopRequest(id: string) {
    const messageToServer = [
      [COMMAND, "ms_alert_stop"],
      ["strategy_id", id]
    ] as ServerCommand;
    
    Connection.getInstance().sendWithNoResponse(messageToServer);

    const callBackListenerIndex = this.callbackListeners.findIndex((x: StreamingAlertCallback) => x.id == id);
    if (callBackListenerIndex > -1) {
      this.callbackListeners.splice(callBackListenerIndex, 1);
    }
  }
}