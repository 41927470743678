export class BrokerInstrument {
    public Symbol:string | undefined = undefined;
    public Last:number = 0;
    public ChangeFromCloseDollar:number  = 0;
    public ChangeFromClosePercent:number = 0;
    public LastClose:number = 0;
    public Time:Date | undefined = undefined;
    public TradeSize:number | undefined = undefined;
    public ReportingExchange:string | undefined = undefined;
    public Type:string | undefined = undefined;
    public TickVolume:number | undefined = undefined;
    public TotalVolume:number | undefined = undefined;
    public Open:number | undefined = undefined;
    public High:number | undefined = undefined;
    public Low:number | undefined = undefined;
    public BidSize:number | undefined = undefined;
    public Bid:number | undefined = undefined;
    public AskSize:number | undefined = undefined;
    public Ask:number | undefined = undefined;
    public ClosePrice:number | undefined = undefined;
    public Created:Date;
    
    constructor() {
        this.Created = new Date();
    }

}