import { parseXml } from "phil-lib/misc";
import {COMMAND, CancelToken, Connection, ServerCommand } from "../../services/connection.client";
import { TopListHistoryDataRequestModel } from "../models/top-list/top-list-history-data-request.model";
import { MetaData } from "../../tipro-data/top-list-request"
import { XmlAttributeToJsonMapper } from "../mappers/xml-attribute-to-json.mapper";
import { TraceLoggingHelpers } from "../models/helpers/trace-logging-helpers";

type TopListHistoryCallback = {
  id: string;
  callback: Function;
  subscriberTopListMetaDataCallback: Function;
  requestModel: TopListHistoryDataRequestModel;
};

export class TopListHistoryRequestBuilder {

  private static instance: TopListHistoryRequestBuilder;
  public static getInstance(): TopListHistoryRequestBuilder {
    if (!this.instance) {
      this.instance = new TopListHistoryRequestBuilder();
    }

    return this.instance;
  }

  private callbackListeners: Array<TopListHistoryCallback> = [];

  public async topListHistoryStartRequest(id: string, requestModel: TopListHistoryDataRequestModel): Promise<void> {
    
    const messageToServer = [
       [COMMAND, "top_list_start"],
       ["window_id", id],
       ["long_form", requestModel.config],
       ["non_filter_columns", 1],
       ["save_to_mru", requestModel.saveToMru ? "1" : "0"]
    ];
    
    var response = Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand);
    
    await response.promise;
    
    return;
  
  }

  private listenCancelToken: any;
  
  public topListHistoryListenRequest(id: string, callback: Function, subscriberTopListMetaDataCallback: Function, requestModel: TopListHistoryDataRequestModel): CancelToken {
  
    const messageToServer = [
      [COMMAND, "top_list_listen"]
    ];

    const callBackListenerIndex = this.callbackListeners.findIndex((x: TopListHistoryCallback) => x.id == id);
    
    if (callBackListenerIndex > - 1)
    {
      this.callbackListeners[callBackListenerIndex] = {
        id: id,
        callback: callback,
        subscriberTopListMetaDataCallback: subscriberTopListMetaDataCallback,
        requestModel: requestModel
      };
    } else {
      this.callbackListeners.push({
        id: id,
        callback: callback,
        subscriberTopListMetaDataCallback: subscriberTopListMetaDataCallback,
        requestModel: requestModel
      });
    }

    if (this.listenCancelToken) {
      return this.listenCancelToken;
    }

    this.listenCancelToken = Connection.getInstance().sendWithStreamingResponse(messageToServer as ServerCommand, (response: string) => {

      const xml = parseXml(response);
      const metaDataTypeName = "info"
      const xmlType = xml?.children[0].getAttribute("TYPE");
      const windowId = xml?.children[0].getAttribute("WINDOW");
      const callBackListener = this.callbackListeners.find((x: TopListHistoryCallback) => x.id == windowId);

      if (callBackListener) {

        if (xmlType == metaDataTypeName) {
          let metaData: MetaData = {};
          metaData.metaDataXml = response;

          if (xml) {
            let json: any = XmlAttributeToJsonMapper.map(xml);
            metaData.metaDataJson = json;
          }

          callBackListener.subscriberTopListMetaDataCallback(metaData);
        }
        else {
          const data: any = {
            xml: xml
          };

          if (callBackListener.requestModel.retryMillisecondsThreshold && callBackListener.requestModel.isRetryRequest) {
            data.requestRetryCount = callBackListener.requestModel.retryCount;
            callBackListener.requestModel.isRetryRequest = false;
            callBackListener.requestModel.retryCount = 0;
          }

          callBackListener.callback(data);
        }
      }
    });

    return this.listenCancelToken;
  }

  public topListHistoryStopRequest(id: string) {

    Connection.getInstance().sendWithNoResponse([
      [COMMAND, "top_list_stop"],
      ["window_id", id]
    ]);

    const callBackListenerIndex = this.callbackListeners.findIndex((x: TopListHistoryCallback) => x.id == id);
    if (callBackListenerIndex > -1) {
      this.callbackListeners.splice(callBackListenerIndex, 1);
    }
  }
}