import { ChartIndicatorParameterModel } from "../models/chart-indicators/chart-indicator-parameter.model";
import { ChartIndicatorResponeModel } from "../models/chart-indicators/chart-indicator-response.model";
import { TIUtilities } from "../utilities/ti-utilities";

export class IndicatorTIQXmlToResponseModel {
    public static map(rawXML: Element): Array<ChartIndicatorResponeModel> {
        const items: Array<ChartIndicatorResponeModel> = [];
        const count = parseInt(rawXML.getAttribute('COUNT') ?? '', 10);
        if (count > 0) {
            for (let i = 1; i <= count; i++) {
                const m = new ChartIndicatorResponeModel();
                m.id = parseInt(rawXML.getAttribute(`id${i}`) ?? '', 10);
                m.referenceName = rawXML.getAttribute(`reference_name${i}`) ?? '';
                m.displayName = rawXML.getAttribute(`name${i}`) ?? '';
                m.abbreviatedName = rawXML.getAttribute(`abbreviated_name${i}`) ?? '';
                m.prefersOwnPane = rawXML.getAttribute(`requires_own_pane${i}`) == '1';
                m.code = rawXML.getAttribute(`formula${i}`) ?? '';
                m.category = rawXML.getAttribute(`category${i}`) ?? '';
                m.colorType = rawXML.getAttribute(`color_type${i}`) ?? '';
                m.indicatorType = rawXML.getAttribute(`column_type${i}`) ?? '';

                m.color = TIUtilities.colorFromServer(rawXML.getAttribute(`one_color${i}`) ?? '', 'blue');
                m.upColor = TIUtilities.colorFromServer(rawXML.getAttribute(`up_color${i}`) ?? '', 'black');
                m.downColor = TIUtilities.colorFromServer(rawXML.getAttribute(`down_color${i}`) ?? '', 'black');
                m.topColor = TIUtilities.colorFromServer(rawXML.getAttribute(`top_color${i}`) ?? '', 'green');
                m.bottomColor = TIUtilities.colorFromServer(rawXML.getAttribute(`bottom_color${i}`) ?? '', 'white');
                items.push(m);
            }
        }
        return items;
    }

    public static mapParameters(rawXML: Element, indicators: Array<ChartIndicatorResponeModel>) {
        const count = parseInt(rawXML.getAttribute('COUNT') ?? '', 10);
        if (count > 0) {
            for (let i = 1; i <= count; i++) {
                const m = new ChartIndicatorParameterModel();
                m.name = rawXML.getAttribute(`NAME${i}`) ?? '';
                m.referenceId = rawXML.getAttribute(`REFERENCE_ID${i}`) ?? '';
                m.description = rawXML.getAttribute(`DESCRIPTION${i}`) ?? '';
                m.format = rawXML.getAttribute(`FORMAT${i}`) ?? '';
                m.minimum = parseFloat(rawXML.getAttribute(`MINIMUM${i}`) ?? '');
                m.maximum = parseFloat(rawXML.getAttribute(`MAXIMUM${i}`) ?? '');
                m.defaultValue = parseFloat(rawXML.getAttribute(`DEFAULT_VALUE${i}`) ?? '');
                m.columnId = parseFloat(rawXML.getAttribute(`COLUMN_ID${i}`) ?? '');
                m.value = parseFloat(rawXML.getAttribute(`VALUE${i}`) ?? '');
                
                if (Number.isNaN(m.value)) {
                    m.value = m.defaultValue;
                }
                
                const ind = indicators.findIndex(x => x.id == m.columnId);
                if (ind > -1) {
                    if (!indicators[ind].parameters) {
                        indicators[ind].parameters = [];
                    }

                    indicators[ind].parameters.push(m);
                }
            }
        }
    }
}