import { Data } from "../../tipro-data/top-list-request";
import { TopListDataRequestModel } from "../models/top-list/top-list-data-request.model";
import { TopListDataResponseModel } from "../models/top-list/top-list-data-response.model";

export class TopListXMLToResponseModelMapper {
    public static map(requestModel: TopListDataRequestModel, response: Data): TopListDataResponseModel | null {
        if (!response) {
            return null;
        }

        const model = new TopListDataResponseModel();
        model.start = response.start;
        model.end = response.end;
        model.rows = response.rows;

        if (requestModel && requestModel.companyNameFilterList?.length && requestModel.extraColumns?.some((x: string) => x && x.indexOf('D_Name') > -1)) {
            for (let i = 0; i < response.rows.length; i++) {
                const row = response.rows[i];
                const companyName = row.get('[D_Name]');
                if (companyName) {
                    const filteredCompanyName = this.replaceFilteredCompanyName(companyName, requestModel.companyNameFilterList);
                    row.set('[D_Name]', filteredCompanyName);
                }
            }
        }

        return model;
    }

    private static replaceFilteredCompanyName(companyName: string, companyNameFilterList: Array<string>): string {
        if (!companyName) {
            return companyName;
        }

        let filteredCompanyName = companyName;
        for (const phrase of companyNameFilterList) {
            const index = filteredCompanyName.toLowerCase().indexOf(phrase.toLowerCase());
            if (index > 0) {
                filteredCompanyName = filteredCompanyName.substring(0, index);
                break;
            }
        }

        return filteredCompanyName;
    }
}