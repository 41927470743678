export class BrokerExecution {

    public Id:string | undefined = undefined;
    public AccountId:string | undefined = undefined;
    public OrderId:string | undefined = undefined;
    public Side:string | undefined = undefined;
    public Symbol:string | undefined = undefined;
    public Shares:number = 0;
    public FilledPrice:number = 0;
    public Commissions:number  = 0;
    public Filled:Date | undefined = undefined;
}