//import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";
//import { parseXml2 } from "./polyfill-DomParser";
import {
  COMMAND,
  createMessageToServer,
  ResponseFromServer,
  responseToString,
  TalkWithServer,
} from "./talk-with-server";

 export type ConnectionCallbacks = {
   onConnected(): void;
   onDisconnected(): void;
   onReconnected(): void;
   onPingHealth(pingMS: number): void;
   onDisconnectedForGood(): void;
   onConnectionStatusUpdate(status:boolean): void;
 };

/**
 * `DISCONNECTED` means we do not want to talk with the server.
 * `CONNECTED` means that we do.
 */
export enum DesiredState {
  CONNECTED,
  DISCONNECTED,
}

/**
 * This interface offers various ways to send a request to the server.
 *
 * There are various layers in this library, and many of them offer this same interface.
 */
export type Outbox = Pick<
  TalkWithServer,
  "sendWithNoResponse" | "sendWithSingleResponse" | "sendWithStreamingResponse"
>;

/**
 * LifecycleManager will periodically and automatically send PING requests to
 * the server.  Ideally the operating system will tell us when a network
 * connection is broken.  That often works, but not always.  Both sides of
 * the connection rely on this stream of pings.
 */
const pingMessage = createMessageToServer([
  [COMMAND, "ping"],
  ["response", true],
]);

/**
 * This class automatically creates and recreates TalkWithServer connections.
 *
 * This class is responsible for adding pauses so we don't retry too quickly.
 * The rule is that we can't create more than one TalkWithServer object per second.
 *
 * Example:  You start the software.  The main program sets the username and password.
 * LifecycleManager _immediately_ connects.  10 minutes later your network goes down.
 * LifecycleManager _immediately_ retries.  The operating system _immediately_ says the
 * new connection failed.  The LifecycleManager will set a timer and try to reconnect
 * in one second.  The LifecycleManager will continue to retry once per seconds until
 * the problem is resolved and we finally reconnect.
 */
export class LifecycleManager {
  /**
   * We might want to cancel the next ping.
   *
   * The state could get somewhat complicated.  It's possible for the main program
   * to enable and disable the connection many times before this class actually
   * sends a ping.  This makes sure we have at most one outstanding ping request.
   */
  #pingTimerId: any = 0;

  /**
   * TODO if we haven't seen a ping in a while, break the connection and wait for
   * this class to automatically reconnect.
   */
  #pingTimeout = new Date();

  private tryPing() {
    const startTime = performance.now();
    this.outbox?.sendWithSingleResponse(pingMessage).promise.then((value: ResponseFromServer) => {
      const responseAsString = responseToString(value);
      if (responseAsString) {
        const elapsedMilliseconds = performance.now() - startTime;
        this.#connectionCallbacks.onPingHealth(elapsedMilliseconds);
      }
    }).catch((error: any) => {
      //TraceLoggingHelpers.log('Ping error', error);
    });

    // TODO monitor the responses and do a reset if we haven't heard a response in a while.
  }

  #desiredState = DesiredState.DISCONNECTED;
  /**
   * Set this to `DesiredState.DISCONNECTED` if you do not want to be connected.
   * If currently connected, break the connection.  Do not try to automatically
   * reconnect.
   *
   * Set this to `DesiredState.CONNECTED` if you want to be connected.  If not
   * already connected, this object will connect shortly.  And this object will
   * automatically reconnect as required.
   */
  get desiredState(): DesiredState {
    return this.#desiredState;
  }
  set desiredState(newValue: DesiredState) {
    if (newValue != this.#desiredState) {
      this.#desiredState = newValue;
      if (this.#desiredState == DesiredState.CONNECTED) {
        this.connectSoon();
        this.#pingTimerId = setInterval(() => {
          this.tryPing();
        }, 10215);
      } else {
        this.disconnect();
        clearInterval(this.#pingTimerId);
      }
    }
  }

  connected(): boolean {
    return this.#talkWithServer?.state == "active";
  }

  disconnectForGood(): void {
    this.desiredState = DesiredState.DISCONNECTED;
    this.#connectionCallbacks.onDisconnectedForGood();
  }

  currentMessageListners() {
    return this.#talkWithServer?.currentMessageListners;
  }

  /**
   * Reconnect to the server with the minimum amount of any other changes.
   * The bulk of the software should resend any required messages to the server
   * so it can pick up exactly where it left off.
   */
  softReset() {
    this.disconnect();
  }

  /**
   * This is the next step when sending a request to the server.
   * And his delivers the replies from the server.
   *
   * Note that the LifecycleManager typically lasts for the lifetime of the
   * main program.  But TalkWithServer only lasts for the lifetime of the
   * network connection.  Each time we want to reconnect, we create a new
   * TalkWithServer.
   */
  #talkWithServer: TalkWithServer | undefined;

  #outbox: Outbox | undefined;

  #connectionCallbacks: ConnectionCallbacks;
  #previouslyConnected = false;

  /**
   *
   * @param onRestart Every time we successfully connect to the server, we call this.
   * This would be an ideal place to send a login command.  
   *
   * @param onConnectionStatusUpdate Every time we successfully ping to the server or it's disconnected, we call this.
   */
  constructor(private readonly onRestart: (outbox: Outbox) => void, connectionCallbacks: ConnectionCallbacks) {
    this.#connectionCallbacks = connectionCallbacks;
  }

  /**
   * Use this to send a message to the server.
   *
   * This is undefined if there is no open connection.
   * You'll get an onRestart callback the next time we open a connection, and you can send your message then.
   */
  get outbox(): Outbox | undefined {
    return this.#outbox;
  }
  private disconnect() {
    this.#talkWithServer?.stop();
    this.#talkWithServer = undefined;
    this.#outbox = undefined;

    // this.onConnectionStatusUpdate(false);
  }

  /**
   * This is the last time that we tried to connect.
   *
   * We are not allowed to try to connect more than once per second.
   */
  #lastConnectTime = new Date(0);

  /**
   * Try to connect to the server.
   *
   * Precondition:  There is no active connection.
   * Either this is a new `LifecycleManager`, or someone called
   * `disconnect()` after the last call to `connect()`.
   */
  private connect() {
    if (this.#talkWithServer || this.#outbox) {
      throw new Error("wtf");
    }
    this.#lastConnectTime = new Date();
    const talkWithServer = new TalkWithServer();
    this.#talkWithServer = talkWithServer;
    talkWithServer.initialized.then((status) => {
      if (talkWithServer === this.#talkWithServer && status === "connected") {
        if (this.#outbox !== undefined) {
          throw new Error("wtf");
        }

        this.#outbox = this.#talkWithServer;
        this.onRestart(this.#outbox);

        if (this.#previouslyConnected) {
          this.#connectionCallbacks?.onReconnected();
        }

        this.#previouslyConnected = true;
        this.#connectionCallbacks?.onConnected();
      }
    });
    talkWithServer.completed.then((history) => {
      // The connection has reported itself as down...
      // ... and this object has not yet done any cleanup
      // on this network connection.  I.e. this LifecycleManager
      // still thinks this TalkWithServer is the current
      // TalkWithServer.

      this.#connectionCallbacks?.onDisconnected();
      if (talkWithServer === this.#talkWithServer) {
        this.#outbox = undefined;
        this.#talkWithServer = undefined;
        // Try to connect again.
        this.connectSoon();
      }
    });
  }
  /**
   * Schedule a connect request.
   *
   * This function starts by checking if we need to pause before connecting.
   * It is safe to call this more than once.
   */
  private connectSoon() {
    const idealStartTime = this.#lastConnectTime.valueOf() + 1000;
    const pauseMs = idealStartTime - Date.now();
    setTimeout(() => {
      if (
        this.desiredState == DesiredState.CONNECTED &&
        this.#talkWithServer === undefined
      ) {
        this.connect();
      }
    }, Math.max(pauseMs, 0));
  }
}
