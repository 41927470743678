import { parseXml } from "phil-lib/misc";
import { AlertHistoryRequestModel } from "../models/alert-history/alert-history-request.model";
import {COMMAND, CancelToken, Connection, ServerCommand, encodeDate } from "../../services/connection.client";

type AlertHistoryCallback = {
    id: string;
    callback: Function;
};

export class AlertHistoryRequestBuilder {

    private static instance: AlertHistoryRequestBuilder;
    public static getInstance(): AlertHistoryRequestBuilder {
      if (!this.instance) {
        this.instance = new AlertHistoryRequestBuilder();
      }
  
      return this.instance;
    }
  
    private callbackListeners: Array<AlertHistoryCallback> = [];
  
    public async alertHistoryStartRequest(id: string, requestModel: AlertHistoryRequestModel): Promise<Element | undefined> {

        const message = [
            [COMMAND, "set_config_info"],
            ["window_id", id],
            ["long_history", "1"],
            ["long_form", requestModel.config],
            ["custom_columns", 1],
            ["non_filter_columns", 1],
            ["long_history_max_count", requestModel.maxCount],
        ] as ServerCommand;

        if (requestModel.startId) {
            message.push(["long_history_start_id", requestModel.startId]);
        }
        else if (requestModel.startTime) {
            message.push(["long_history_start_time", encodeDate(requestModel.startTime)]);
        }

        if (requestModel.endTime) {
            message.push(["long_history_end_time", encodeDate(requestModel.endTime)]);
        }

        var response = await Connection.getInstance().sendWithSingleResponse(message as ServerCommand).promise;

        const xml = parseXml(response); 

        return xml;
    }


    public alertHistoryListenRequest(id: string, callback: Function): CancelToken {

        const messageToServer = [
            [COMMAND, "history_listen"]
        ] as ServerCommand;

        this.callbackListeners.push({id: id, callback: callback});
        return Connection.getInstance().sendWithStreamingResponse(messageToServer, (response: string) => {
            const xml = parseXml(response);
            
            const windowId = xml?.children?.item(0)?.getAttribute('WINDOW');
            const callBackListener = this.callbackListeners.find((x: AlertHistoryCallback) => x.id == windowId);
            if (callBackListener) {
                callBackListener.callback(xml);
            }
        });
    }

    public alertHistoryStopRequest(id: string) {
        const messageToServer = [
            [COMMAND, "remove_config_info"],
            ["window_id", id]
        ] as ServerCommand;
       
        Connection.getInstance().sendWithNoResponse(messageToServer);

        const callBackListenerIndex = this.callbackListeners.findIndex((x: AlertHistoryCallback) => x.id == id);
        if (callBackListenerIndex > -1) {
            this.callbackListeners.splice(callBackListenerIndex, 1);
        }
    }
}