import { Data, MetaData, TopListRequest } from "../tipro-data/top-list-request";
import { TopListXMLToResponseModelMapper } from "./mappers/top-list-xml-to-response-model.mapper";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";
import { TopListDataRequestModel } from "./models/top-list/top-list-data-request.model";
import { TopListDataResponseModel } from "./models/top-list/top-list-data-response.model";

export type TopListDataCallbackFunction = (responseModel: ClientResponseModel<Data>) => void;

export type TopListMetaDataCallbackFunction = (response: ClientResponseModel<MetaData>) => void;

export class TopListClient {


    private topListDataUnsubscribeWithCancelTokenCallback: any = null;

    constructor() {}

    public subscribe(requestModel: TopListDataRequestModel, setResponseOnDataCallback: TopListDataCallbackFunction, setResponseOnMetadataCallback?: TopListMetaDataCallbackFunction): TopListClient {
        this.unsubscribe();

        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Data>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     setResponseOnDataCallback(responseModel); 
        //     return this;
        // }

        requestModel.removeConfigStringPropertyIfPropertyNameSet('resultCount', 'count')

        const mainTopListRequest = new TopListRequest();
        mainTopListRequest.singleSymbol = requestModel.singleSymbol;
        mainTopListRequest.resultCount = requestModel.resultCount;
        mainTopListRequest.sortFormula = requestModel.sortFormula;
        mainTopListRequest.collaborate = requestModel.collaborate;
        mainTopListRequest.skipMetaData = requestModel.skipMetaData;
        mainTopListRequest.streaming = requestModel.streaming ?? false;
        mainTopListRequest.collaborateColumns = requestModel.collaborateColumns;
        mainTopListRequest.outsideMarketHours = requestModel.outsideMarketHours;

        if (requestModel.extraColumns) {
            for (const column of requestModel.extraColumns) {
                // TraceLoggingHelpers.log('column ', column)
                let extraColumn = column;
                if (column[0] != '[') {
                    extraColumn = '[' + extraColumn;
                }

                if (column[column.length - 1] != ']') {
                    extraColumn = extraColumn + ']';
                }

                TraceLoggingHelpers.log('add extraColumn ', extraColumn)
                mainTopListRequest.addExtraColumn(extraColumn);
            }
        }

        this.topListDataUnsubscribeWithCancelTokenCallback = mainTopListRequest.sendStreaming({
            onData(data: Data) {
                const responseModel = new ClientResponseModel<TopListDataResponseModel>();
                if (data) {
                    responseModel.isSuccess = true;

                    responseModel.data = TopListXMLToResponseModelMapper.map(requestModel, data);
                    setResponseOnDataCallback(responseModel);
                } else {
                    responseModel.message = 'Response XML Empty';
                    setResponseOnDataCallback(responseModel);
                }
            },
            onMetaData(metaData: MetaData) {
                const responseModel = new ClientResponseModel<MetaData>();
                if (metaData) {
                    responseModel.isSuccess = true;
                    responseModel.data = metaData;
                    setResponseOnMetadataCallback && setResponseOnMetadataCallback(responseModel);
                } else {
                    responseModel.message = 'Response XML Empty';
                    setResponseOnMetadataCallback && setResponseOnMetadataCallback(responseModel);
                }
            }
        });

        return this;
    }

    public unsubscribe() {
        TraceLoggingHelpers.log('unsubscribe ', this.topListDataUnsubscribeWithCancelTokenCallback)
        if (this.topListDataUnsubscribeWithCancelTokenCallback) {
            this.topListDataUnsubscribeWithCancelTokenCallback();
            this.topListDataUnsubscribeWithCancelTokenCallback = null;
        }
    }
}