/**
 * Request Company Profile Information 
 *
 * This code was copied and translated from
 * https://github.com/TI-Pro/TIPro/blob/development/* TiPro/TIProData/CompanyProfileManager.cs
 */

import { parseXml } from "phil-lib/misc";

import {COMMAND, Connection, ServerCommand} from "../../services/connection.client";

export async function companyProfileRequest(symbol: string): Promise<Element | undefined> {
 
  const messageToServer = [
    [COMMAND, "get_profile"],
    ["symbol", symbol]
  ] as ServerCommand;

  var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
    
  const xml = parseXml(response);
 
  return xml;

}