import { parseXml } from "phil-lib/misc";
import {COMMAND, Connection, ServerCommand} from "../../services/connection.client";
import { SaveToCloudRequestModel } from "../models/cloud-link/save-to-cloud-request.model";

export async function importCloudLinkRequest(cloudLink: string): Promise<Element | undefined> {
  const messageToServer = [
    [COMMAND, "cloud_import"],
    ["code", cloudLink]
  ] as ServerCommand;

  var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
    
  const xml = parseXml(response);
 
  return xml;

}

export async function saveToCloudRequest(saveToCloudRequest: SaveToCloudRequestModel): Promise<Element | undefined> {


  const messageToServer = [
    [COMMAND, "save_to_cloud"],
    ["layout", saveToCloudRequest.layoutXml],
    ["icon", saveToCloudRequest.icon],
    ["window_count", 1],
    ["clearPreviousLayout", saveToCloudRequest.clearPreviousLayout],
    ["fill_screen", saveToCloudRequest.fillScreen],
    ["short_description", saveToCloudRequest.shortDescription],
    ["long_description", saveToCloudRequest.longDescription]
  ] as ServerCommand;

  var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
    
  const xml = parseXml(response);
 
  return xml;

}