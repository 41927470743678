export class CreatePriceAlertRequestModel {
    public id = '';
    public symbol = '';
    public status = '';
    public notes = '';
    public price = 0;
    public isLong = false;
    public longAfter = false;
    public afterHours = false;
    public invalidPrice: number | null = null;
    public expires: Date | null = null;
    public triggered: Date | null = null;
    public created: Date = null as any;
    public updated: Date = null as any;
}