//import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";
import { ConnectionCallbacks, DesiredState, LifecycleManager, Outbox } from "./lifecycle-manager";
import { LoginManager } from "./login-manager";
import { SendManager } from "./send-manager";

export class ConnectionMaster {

  // private static instance: ConnectionMaster | undefined;
    
  // public static get Instance(): ConnectionMaster {
      
  //     if (!this.instance) {
  //         this.instance = new ConnectionMaster();
  //     }

  //     return this.instance;
  // }

  #connectionCallbacks: any;

  public SetConnectionCallbacks(connectionCallbacks: ConnectionCallbacks ){
    this.#connectionCallbacks = connectionCallbacks;
  }

  private onRestart(outbox: Outbox): void {
    this.#loginManager.connected(outbox);
    this.#sendManager.connected();
  }

  private onConnectionCallbacks: ConnectionCallbacks = {
    onConnected: () => {
      this.#connectionCallbacks?.onConnected();
      this.#connectionCallbacks?.onConnectionStatusUpdate(true);
    },
    onDisconnected: () => {
      this.#connectionCallbacks?.onDisconnected();
      this.#connectionCallbacks?.onConnectionStatusUpdate(false);
    },
    onReconnected: () => {
      this.#connectionCallbacks?.onReconnected();
      this.#connectionCallbacks?.onConnectionStatusUpdate(true);
    },
    onPingHealth: (pingMS: number) => {
      this.#connectionCallbacks?.onPingHealth(pingMS);
      this.#connectionCallbacks?.onConnectionStatusUpdate(true);
    },
    onDisconnectedForGood: () => {
      this.#connectionCallbacks?.onDisconnectedForGood();
      this.#connectionCallbacks?.onConnectionStatusUpdate(false);
    },
    onConnectionStatusUpdate: (status) => {
      this.#connectionCallbacks?.onConnectionStatusUpdate(status);
    }
  }
  
  #lifecycleManager = new LifecycleManager(this.onRestart.bind(this), this.onConnectionCallbacks);
  #loginManager = new LoginManager(this.#lifecycleManager);
  #sendManager = new SendManager(this.#lifecycleManager);
  get lifecycleManager(): Pick<LifecycleManager, "softReset" | "connected"> {
    return this.#lifecycleManager;
  }
  get outbox(): Outbox {
    return this.#sendManager;
  }
  get loginManager(): Pick<LoginManager, "login" | "logout" | "isLoggedIn" | "isDemo" | "getCurrentUser"> {
    return this.#loginManager;
  }

  public reconnect() {
    this.#lifecycleManager.desiredState = DesiredState.DISCONNECTED;
    this.#lifecycleManager.desiredState = DesiredState.CONNECTED;
  }
}
