import { parseXml } from "phil-lib/misc";

import {Connection, ServerCommand} from "../services/connection.client";

export class ThrottledMarketDataClient {

    private static s_instance: ThrottledMarketDataClient | null = null;

    private constructor() {}

    public static GetInstance(): ThrottledMarketDataClient {
 
        if (!this.s_instance) 
            this.s_instance = new ThrottledMarketDataClient();
         
         return this.s_instance;
    }
    private _connected = false;

    private _subscriptions:Subscription[] = [];

    private _connectionCallbacks: ConnectionCallback[] = [];

    public Connect(connectionCallback?:ConnectionCallback): Promise<string> {
        
        return new Promise<string>((resolve,reject) => {
            if( connectionCallback)
                this.AddConnectionCallback(connectionCallback);

            if( this._connected){  
                resolve("The MarketData Service is already connected");
            }
            else{
                resolve("Successfully connected to The MarketData Service");
                Connection.getInstance().setMarketDataListener(this.MarketDataMessageReceived.bind(this));
                this._connected = true;
            }            
        });
    }

    public AddConnectionCallback( connectionCallback: ConnectionCallback){
        this._connectionCallbacks.push(connectionCallback);
    }

    public SubscribeTradeQuote(symbol:string, location:string, unsubscribePrevious:boolean, tradeQuoteCallback: TradeQuoteCallback  ){
        
        if(unsubscribePrevious)
           this.unSubscribePrevious(location);

        let subscription =  this._subscriptions.find(obj => obj.symbol === symbol)

        if(subscription)
        {
            subscription.count++;
            subscription.locations.push({ id:location, tradeQuoteCallback:tradeQuoteCallback});

            let connectionMessage:ConnectionStaus = {
                Code: 2,
                Message: "Already Subscribed to Symbol:" + symbol 
            }
            this._connectionCallbacks.forEach(connectionCallback => {
                connectionCallback(connectionMessage);
            });

        }
        else
        {
            let connectionMessage:ConnectionStaus = {
                Code: 3,
                Message: "Subscribing to Symbol:" + symbol 
            }
            this._connectionCallbacks.forEach(connectionCallback => {
                connectionCallback(connectionMessage);
            });

            let messageToServer = [
                ["command",    "market_data"],
                ["subcommand", "subscribe"],
                ["symbol",      symbol]
            ];
            Connection.getInstance().subscribeMarketData(messageToServer as ServerCommand);

            subscription = {count:1, symbol:symbol, locations:[]};
        
            subscription.locations.push({ id:location, tradeQuoteCallback:tradeQuoteCallback})
    
            this._subscriptions.push(subscription);
        }
    }

    private unSubscribePrevious(location:string){
        
        for (let i = this._subscriptions.length - 1; i >= 0; i--)
        {
            for (let j = 0; j < this._subscriptions[i].locations.length; j++)
            {
                if (this._subscriptions[i].locations[j].id === location)
                {
                    this._subscriptions[i].locations.splice(j,1);
                    this._subscriptions[i].count--;
                    break;
                }
            }

            var symbol = this._subscriptions[i].symbol; 
            if( this._subscriptions[i].count === 0){

                let messageToServer = [
                    ["command",    "market_data"],
                    ["subcommand", "unsubscribe"],
                    ["symbol",      symbol]
                ];
               
                Connection.getInstance().unSubscribeMarketData(messageToServer as ServerCommand);
               

                this._subscriptions.splice(i, 1);

                let connectionMessage:ConnectionStaus = {
                    Code: 1,
                    Message: "UnSubscribing Symbol:" + symbol 
                }

                this._connectionCallbacks.forEach(connectionCallback => {
                    connectionCallback(connectionMessage);
                });
            }
        }
    }

    public UnSubscribeTradeQuote(symbol:string, location:string){
        
        let subscription = this._subscriptions.find(obj => obj.symbol === symbol)

        if(!subscription) return;
        
        for (let i = 0; i < this._subscriptions.length; i++)
        {
            if( this._subscriptions[i].symbol == symbol)
            {
                for (let j = 0; j < this._subscriptions[i].locations.length; j++)
                {
                    if (this._subscriptions[i].locations[j].id === location)
                    {
                        this._subscriptions[i].locations.splice(j,1);
                        break;
                    }
                }
                
                subscription.count--;

                if( subscription.count === 0){

                    let messageToServer = [
                        ["command",    "market_data"],
                        ["subcommand", "unsubscribe"],
                        ["symbol",      symbol]
                    ];

                    Connection.getInstance().unSubscribeMarketData(messageToServer as ServerCommand);

                    this._subscriptions.splice(i, 1);

                    let connectionMessage:ConnectionStaus = {
                        Code: 1,
                        Message: "UnSubscribing Symbol:" + symbol 
                    }
    
                    this._connectionCallbacks.forEach(connectionCallback => {
                        connectionCallback(connectionMessage);
                    });

                    break;
                }
            }
        }
    }

    private MarketDataMessageReceived(serverMessage:string)
    {
                let message = JSON.parse(serverMessage);
                switch(message.MessageType)
                {
                    //Connection Opened 30:
                    //Heartbeat 31:
                    case 30:
                    case 31:
                        let connectionMessage:ConnectionStaus = {
                            Code: message.MessageType,
                            Message: message.Data ?? message.Message
                        }
                        this._connectionCallbacks.forEach(connectionCallback => {
                            connectionCallback(connectionMessage);
                        });
                        break;
                    case 53:

                        //TradeQuote
                        let prevClosePrice = message.Data[12];
                        let dollarChange =  (message.Data[3] - prevClosePrice);
                        let percentChange = (dollarChange/prevClosePrice)*100;

                        let tradeQuote:TradeQuote = {
                            Symbol: message.Data[0],
                            TradeTime: new Date(message.Data[1]),
                            TradeTimeUtc: new Date(message.Data[11]),
                            TradeSize: message.Data[2],
                            TradePrice: Number(message.Data[3].toFixed(2)),
                            QuoteTime: new Date(message.Data[4]),
                            BidSize: message.Data[5],
                            Bid: message.Data[6],
                            AskSize: message.Data[7],
                            Ask: message.Data[8],
                            Volume: message.Data[9],
                            ReportingExchange: message.Data[10],
                            DollarChange: Number(dollarChange.toFixed(4)),
                            PercentChange: Number(percentChange.toFixed(4)),
                            PreviousClose: prevClosePrice
                        };

                        this._subscriptions.forEach(subscription => {
                            if( subscription.symbol == tradeQuote.Symbol){
                                subscription.locations.forEach(location => {
                                    if(location.tradeQuoteCallback)
                                       location.tradeQuoteCallback(tradeQuote);
                                });
                            }
                        });

                        break;
                }

    }
    
}

interface Subscription {
    count:number;
    symbol:string; 
    locations: Location[];
}

interface Location {
    id:string;
    tradeQuoteCallback: TradeQuoteCallback;
}

export type TradeQuoteCallback = (arg:TradeQuote) => void;
export type ConnectionCallback = (arg:ConnectionStaus) => void;
export type ConnectionStaus = {
    Code: number,
    Message: string
}
export type TradeQuote = {
    Symbol: string,
    TradeTime: Date,
    TradeTimeUtc: Date,
    TradeSize: number,
    TradePrice:number,
    QuoteTime:Date,
    BidSize:number,
    Bid:number,
    AskSize:number,
    Ask:number,
    Volume:number,
    ReportingExchange:string,
    PreviousClose: number,
    DollarChange:number,
    PercentChange:number
}