import { customAlphabet } from 'nanoid';

export class IdGenerator {
    
    public static NewId(): string {
        
        const generateShortId = customAlphabet('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890', 8);
        
        return generateShortId();
    }
}