import { histogramRequest } from "./api-request-builders/histogram-request.builder";
import { HistogramXMLToResponseModelMapper } from "./mappers/histogram-xml-to-response-model.mapper";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { HistogramRequestModel } from "./models/histogram/histogram-request.model";
import { HistogramResponseModel } from "./models/histogram/histogram-response.model";

export class HistogramClient {
    constructor() {}

    public async getHistogram(requestModel: HistogramRequestModel): Promise<ClientResponseModel<HistogramResponseModel>> {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<HistogramResponseModel>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     return responseModel;
        // }  

        const response = await histogramRequest(requestModel.configs);
        
        const model = new ClientResponseModel<HistogramResponseModel>();
        if (!response) {
            return model;
        }

        const result = HistogramXMLToResponseModelMapper.map(response, requestModel.filters);

        model.isSuccess = true;
        model.data = result;

        return model;
    }
}