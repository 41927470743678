export class ChartIndicatorParameterModel {
    public name = '';
    public referenceId = '';
    public format = '';
    public description = '';
    public minimum = 0;
    public maximum = 0;
    public columnId = 0;
    public defaultValue = 0;
    public value = 0;
}