/**
 * Request Holiday Information 
 *
 * This code was copied and translated from
 * https://github.com/TI-Pro/TIPro/blob/development/* TiPro/TIProData/HolidayManager.cs
 */

import { parseXml } from "phil-lib/misc";
import {COMMAND, CancelToken, Connection, ServerCommand} from "../../services/connection.client";

export async function holidayRequest(): Promise<Element | undefined> {
  const messageToServer = [
    [COMMAND, "holidays_command"],
    ["subcommand", "get_holidays"]
  ] as ServerCommand;
  
  var response = await Connection.getInstance().sendWithSingleResponse(messageToServer as ServerCommand).promise;
 
  const xml = parseXml(response);

  return xml;

}