import { ConnectionSettings } from "../../../connection-settings";

export class TraceLoggingHelpers {
    public static log(message: string, extraParameters?: any) {
        const traceLoggingEnabled = ConnectionSettings.getInstance().traceLogging;
        if (traceLoggingEnabled) {
            const timestamp = this.getTraceLoggingTimeStamp();
            console.log(timestamp, 'API TRACELOGGING,', message, extraParameters ? extraParameters : '');
        }
    }

    public static logWarn(message: string, extraParameters?: any) {
        const timestamp = this.getTraceLoggingTimeStamp();
        console.warn(timestamp, 'API TRACELOGGING,', message, extraParameters);
    }

    public static logError(message: string, extraParameters?: any) {
        const timestamp = this.getTraceLoggingTimeStamp();
        console.error(timestamp, 'API TRACELOGGING,', message, extraParameters);
    }

    private static getTraceLoggingTimeStamp(): string {
        const currentDate = new Date();

        const padWithZeros = (number: number, length: number) => {
            return String(number).padStart(length, '0');
        }
        // Extract individual components
        const year = currentDate.getFullYear();
        const month = padWithZeros(currentDate.getMonth() + 1, 2); // Adding 1 to month since it is zero-based
        const day = padWithZeros(currentDate.getDate(), 2);
        const hours = padWithZeros(currentDate.getHours(), 2);
        const minutes = padWithZeros(currentDate.getMinutes(), 2);
        const seconds = padWithZeros(currentDate.getSeconds(), 2);
        const milliseconds = padWithZeros(currentDate.getMilliseconds(), 3);

        // Determine if it's AM or PM
        const meridiem = parseFloat(hours) >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        const hours12 = padWithZeros(parseFloat(hours) % 12 || 12, 2);

        // Create the formatted date string
        const formattedDate = `${month}-${day}-${year} ${hours12}:${minutes}:${seconds}.${milliseconds} ${meridiem}`;
        return `${formattedDate}`;
    }
}