import { testXml2 } from "./polyfill-DomParser";
import { parseIntX, parseTimeT } from "phil-lib/misc";
import { DesiredState, LifecycleManager, Outbox } from "./lifecycle-manager";
import {
  COMMAND,
  createMessageToServer,
  responseToString,
} from "./talk-with-server";
import { ConnectionSettings } from "../connection-settings";
//import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";

export class User {
  public userId = 0;
  public username = '';
  public accountStatus = '';

  constructor(data?: any) {
    if (data) {
      this.userId = data.userId;
      this.username = data.username;
      this.accountStatus = data.accountStatus;
    }
  }
}

export class LoginManager {

  #username = "";
  #password = "";

  private user: User | null = null;
  private loginResolve: any = null;
  private loginReject: any = null;


 
  private ready() {
    return this.#username != "" && this.#password != "";
  }
  /**
   * Try to connect to the server with the given credentials.
   *
   * This object will automatically disconnect and stop retrying if there are
   * account problems.  The main program will have to call login() again in
   * those cases.  Typically the user has to do some action, like clicking on
   * the red status ball, to re-login.
   * @param username TI username
   * @param password TI password
   */
  login(location:string, username: string, password: string, applicationId: number, destination?: string, traceLogging?: boolean): Promise<User | null> {
    return new Promise<User | null>((resolve, reject) => {
      this.#username = username;
      this.#password = password;
      this.loginResolve = resolve;
      this.loginReject = reject;

      ConnectionSettings.setInstance(location, applicationId, username, password, destination, traceLogging);
      this.lifecycleManager.desiredState = DesiredState.DISCONNECTED;
      // The disconnect happens immediately.  So, if were are already
      // connected, and we are ready() to connect again right now,
      // this is effectively a hard reset.
      if (this.ready()) {
        this.lifecycleManager.desiredState = DesiredState.CONNECTED;
      }
    });
  }
  /**
   * Disconnect from the server.
   */
  logout() {
    this.user = null;
    this.loginResolve = null;
    this.loginReject = null;
    this.login("", "", "", 0, undefined, ConnectionSettings.getInstance().traceLogging);
  }

  isLoggedIn(): boolean {
    return !!this.#username && this.accountState?.toLowerCase() == 'good';
  }

  isDemo(): boolean {
    return !!this.#username && this.#username.toLowerCase() == 'demo';
  }

  getCurrentUser(): User | null {
    return this.user;
  }

  constructor(private readonly lifecycleManager: LifecycleManager) {  }

  /**
   *
   * @param outbox A safe place to send messages.
   * Normal code asks the `LifecycleManager` or the `SendManager` to find the current
   * connection, and there might not be any current connection.
   *
   * In this case `ConnectionMaster` explicitly told us where to send our requests.
   * This allows us to skip the line and put login commands before any queued up
   * commands.  And this works perfectly even if the outbox goes down while we're
   * still working on these initialization messages.
   */
  connected(outbox: Outbox) {
    const message = createMessageToServer([
      [COMMAND, "login"],
      ["username", this.#username],
      ["password", this.#password],
      ["vendor_id", "pure javascript api"],
      // unique_id is an optional field.
      // It hasn't been used in a long time.
      // It will show up in the log file and the user_cookie table.
    ]);
    console.log("sending login");
    let n = 0;
    outbox.sendWithStreamingResponse(message, (response) => {
      if (!response) {
        // The connection is broken.  Some software uses this to retry.
        // However, the LoginManager is special and it will receive another
        // connected() call when it is time to retry.
        // TODO this would be a good place to update the account data, stored in this
        // object, and update any listeners.  (I.e. set a lot of things to undefined.)
        // Just be careful.  Does the close message always come before the restart message?!

        if (this.loginReject) {
          this.loginReject('response null');
          this.loginResolve = null;
          this.loginReject = null;
        }
      } else {
        const responseString = responseToString(response);
        const parsedResponse = testXml2(responseString);
        if (parsedResponse.error) {
          // For the programmer.  Temporary.  TODO review **all** console messages before calling
          // this library "ready."
          console.error(
            "Garbled Login Message",
            parsedResponse.error,
            responseString
          );

          if (this.loginReject) {
            this.loginReject('parsedResponse error');
            this.loginResolve = null;
            this.loginReject = null;
          }

          // We're experiencing some type of internal problem.  There are only so many things we
          // can do.  We break the connection and let lifecycleManager try agin.  Like when you
          // don't know what's wrong with a web page so you hit refresh, but automatic.  Like
          // reseating a cable or rebooting a computer.
          this.lifecycleManager.softReset();
        } else {
          /* The message might look like
           *   <API>
           *    <ACCOUNT_STATUS NEXT_PAYMENT="1689663600" ODDSMAKER="2147483647" STATE="good" USER_ID="4" />
           *    <MESSAGES_TO_USER read_count="0" requires_acknowledgement_count="0" unread_count="2" />
           *    <RESTART SEQUENCE="72126" />
           *   </API>
           * or
           *   <API>
           *     <ACCOUNT_STATUS STATE="another user" />
           *     <STATUS DISCONNECT_FOR_GOOD="1" />
           *   </API>
           * See cpp_alert_server/source/micro_proxy/UserInfo.C for the code that reads our requests and
           * produces these responses.
           */
          // What should you do if one of these values is missing?  Unfortunately that's not well specified.
          // It might mean no change, keep the old value.
          // Or we might want to store undefined as the new current value for the property.
          // Probably different for different properties and different times.  🙁
          if (!parsedResponse.parsed) return;
          
          const main = parsedResponse.parsed.documentElement;
          const accountStatus = main.getElementsByTagName(
            "ACCOUNT_STATUS"
          )[0] as Element | undefined;
          const messagesToUser = main.getElementsByTagName(
            "MESSAGES_TO_USER"
          )[0] as Element | undefined;
          const restart = main.getElementsByTagName("RESTART")[0] as
            | Element
            | undefined;
          this.nextPayment = parseTimeT(
            accountStatus?.getAttribute("NEXT_PAYMENT")
          );
          this.oddsMakerStatus = parseIntX(
            accountStatus?.getAttribute("ODDSMAKER")
          );
          if (this.oddsMakerStatus == 2147483647) {
            this.oddsMakerStatus = Infinity;
          }
          this.accountState = accountStatus?.getAttribute("state") || accountStatus?.getAttribute("STATE") || undefined;
          this.userId = parseIntX(accountStatus?.getAttribute("USER_ID"));
          this.restartSequence = restart?.getAttribute("SEQUENCE") ?? undefined;
          const status = main.getElementsByTagName(
            "STATUS"
          )[0] as Element | undefined;
          const disconnectForGood =
            status?.getAttribute("DISCONNECT_FOR_GOOD") == "1";

          const traceLoggingEnabled = ConnectionSettings.getInstance().traceLogging;
          if (traceLoggingEnabled) {
            // TraceLoggingHelpers.log('Response:', {
            //   loginResponse: responseString,
            //   n: n++,
            //   now: new Date(),
            //   rawResponse: response,
            //   nextPayment: this.nextPayment,
            //   oddsMakerStatus: this.oddsMakerStatus,
            //   accountState: this.accountState,
            //   userId: this.userId,
            //   restartSequence: this.restartSequence,
            //   disconnectForGood: disconnectForGood,
            // });
          }

          this.user = new User({
            userId: this.userId,
            username: this.#username,
            accountStatus: this.accountState,
            disconnectForGood: disconnectForGood
          });

          if (this.isLoggedIn()) {
            if (this.loginResolve) {
              this.loginResolve(this.user);
            }
          } else {
            if (this.loginReject) {
              this.loginReject(this.accountState);
            }
          }

          this.loginResolve = null;
          this.loginReject = null;

          if (disconnectForGood) {
            // Disconnect and don't automatically reconnect.
            this.lifecycleManager.disconnectForGood();
            this.user = null;
          }
        }
      }
    });
  }
  

  // Various state that we receive as part of the login message.
  // There should be a way for other parts of the code to listen for changes.  TODO
  oddsMakerStatus: number | undefined;
  accountState: string | undefined;
  nextPayment: Date | undefined;
  userId: number | undefined;
  private restartSequence: string | undefined;
  // status of messages for users... TODO
}

// TODO add some type of listener.
// The main program might want to display the name of the current user.
// The main program might want a status light to say we are or or not logged in.
// etc.
