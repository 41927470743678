import { ClientResponseModel } from "../lib-export/everything";
import { AlertHistoryRequestBuilder } from "./api-request-builders/alert-history-request.builder";
//import { ConnectionMasterClient } from "./connection-master.client";
import { AlertHistoryXMLToResponseModelMapper } from "./mappers/alert-history-xml-to-response-model.mapper";
import { AlertHistoryRequestModel } from "./models/alert-history/alert-history-request.model";
import { AlertHistoryResponeModel } from "./models/alert-history/alert-history-response.model";
import { IdGenerator } from "./models/helpers/id-generator";

export class AlertHistoryClient {

    private alertHistoryRequestBuilder: AlertHistoryRequestBuilder;
    private id = IdGenerator.NewId();

    constructor() {
        this.alertHistoryRequestBuilder = AlertHistoryRequestBuilder.getInstance();
    }

    public getAlertHistory(requestModel: AlertHistoryRequestModel, setResponseOnDataCallback: Function) {
        this.start(requestModel, setResponseOnDataCallback);
    }

    public subscribe(requestModel: AlertHistoryRequestModel, setResponseOnDataCallback: Function): AlertHistoryClient {
        this.start(requestModel, setResponseOnDataCallback);
        return this;
    }

    private cancelToken: any;
    private start(requestModel: AlertHistoryRequestModel, setResponseOnDataCallback: Function) {
        // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
        //     const responseModel = new ClientResponseModel<Array<AlertHistoryResponeModel>>();
        //     responseModel.isSuccess = false;
        //     responseModel.message = 'Not logged in, please Login';
        //     setResponseOnDataCallback(responseModel);
        //     return;
        // }

        const cancelToken = this.alertHistoryRequestBuilder.alertHistoryListenRequest(this.id, (responseXML: Element | null) => {
            // if (ConnectionMasterClient.getInstance().isNotLoggedIn()) {
            //     const responseModel = new ClientResponseModel<Array<AlertHistoryResponeModel>>();
            //     responseModel.isSuccess = false;
            //     responseModel.message = 'Not logged in, please Login';
            //     setResponseOnDataCallback(responseModel); 
            //     return;
            // }
            
            const responseModel = new ClientResponseModel<Array<AlertHistoryResponeModel>>();
            if (responseXML) {
                const data = AlertHistoryXMLToResponseModelMapper.map(responseXML);;
                if (data?.length && data[0].SYMBOL && data[0].SYMBOL.indexOf('**') > -1) {
                    responseModel.isSuccess = false;
                    responseModel.message = data[0].DESCRIPTION;
                } else {
                    responseModel.isSuccess = true;
                }
                responseModel.data = data;
                setResponseOnDataCallback(responseModel);
            } else {
                responseModel.message = 'Response XML Empty';
                setResponseOnDataCallback(responseModel);
            }
        });

        if (!this.cancelToken) {
            this.cancelToken = cancelToken;
        } else {
            cancelToken();
        }

        this.alertHistoryRequestBuilder.alertHistoryStartRequest(this.id, requestModel);
    }

    public stopAlertHistory() {
        this.stop();
    }

    public unsubscribe() {
        this.stop();
    }

    private stop() {
        // if (this.cancelToken) {
        //     this.cancelToken();
        //     this.cancelToken = null;
        // }
        this.alertHistoryRequestBuilder.alertHistoryStopRequest(this.id);
    }
}